import styled from "styled-components";
import PropTypes from "prop-types";

const Image33Icon = styled.img`
  align-self: stretch;
  height: 151px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  display: none;
`;
const Auctionimage = styled.div`
  align-self: stretch;
  height: 151px;
  border-radius: var(--br-mini);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url("/image-33@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-image: ${(p) => p.propBackgroundImage};
`;
const ProductName = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: capitalize;
  font-weight: 500;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
`;
const Usd = styled.span`
  line-height: 27px;
  font-weight: 600;
`;
const Span = styled.span`
  line-height: 24px;
  white-space: pre-wrap;
  color: var(--color-gray-200);
`;
const Usd1 = styled.span``;
const Usd79400KgContainer = styled.div`
  position: relative;
  text-transform: capitalize;
  display: inline-block;
  min-width: 124px;
`;
const Auctionprice = styled.div`
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
  box-sizing: border-box;
`;
const Ends1d6h = styled.div`
  position: relative;
  line-height: 24px;
  text-transform: capitalize;
  display: inline-block;
  min-width: 78px;
`;
const Auctionendtime = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px var(--padding-4xl) 0px var(--padding-3xl);
  color: var(--color-gray-200);
`;
const Pricetime = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px 0px;
  gap: var(--gap-3xs);
  color: var(--color-darkorange);
`;
const BidsCurrent = styled.span`
  line-height: 24px;
  color: var(--color-gray-200);
`;
const Usd394008Container = styled.div`
  flex: 1;
  position: relative;
  text-transform: capitalize;
`;
const Currentbids = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--salad);
`;
const DailyCatchStatusContainer = styled.div`
  flex: 1;
  position: relative;
  line-height: 24px;
  text-transform: capitalize;
`;
const Minbid = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Quantity = styled.span`
  color: var(--medium-gray);
`;
const KgAvailableForContainer = styled.div`
  flex: 1;
  position: relative;
  line-height: 25.5px;
  text-transform: capitalize;
`;
const Auctionlabel = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--medium-gray);
`;
const StarIcon = styled.img`
  height: 13px;
  width: 13px;
  position: relative;
  min-height: 13px;
`;
const StarIcon1 = styled.img`
  height: 13px;
  width: 13px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 13px;
`;
const Auctionratings = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px var(--padding-157xl) 0px 0px;
  gap: var(--gap-9xs);
  @media screen and (max-width: 450px) {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const Auctiondetails = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  font-size: var(--regular-text-15-size);
  color: var(--color-gray-200);
  font-family: var(--regular-text-20);
`;
const Cardinner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-sm-5);
`;
const HomeauctioncardRoot = styled.div`
  min-width: 297.5px;
  border-radius: var(--br-xl);
  background-color: var(--neutral-white);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-xl) var(--padding-mini);
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--color-black);
  font-family: var(--font-ibm-plex-sans);
`;

const HomeAuctionCard = ({ className = "", propBackgroundImage, image33 }) => {
  return (
    <HomeauctioncardRoot className={className}>
      <Cardinner>
        <Auctionimage propBackgroundImage={propBackgroundImage}>
          <Image33Icon alt="" src={image33} />
        </Auctionimage>
        <ProductName>Product Name</ProductName>
        <Auctiondetails>
          <Pricetime>
            <Auctionprice>
              <Usd79400KgContainer>
                <Usd1>
                  <Usd>USD $794.00</Usd>
                  <Span>{`  `}</Span>
                </Usd1>
                <Span>
                  <Usd1>kg</Usd1>
                </Span>
              </Usd79400KgContainer>
            </Auctionprice>
            <Auctionendtime>
              <Ends1d6h>Ends 1d 6h</Ends1d6h>
            </Auctionendtime>
          </Pricetime>
          <Currentbids>
            <Usd394008Container>
              <Usd>USD $394.00</Usd>
              <BidsCurrent> 8 Bids - Current Bid</BidsCurrent>
            </Usd394008Container>
          </Currentbids>
          <Minbid>
            <DailyCatchStatusContainer>
              USD $394.00 8 Bids - Min Bid
            </DailyCatchStatusContainer>
          </Minbid>
          <Minbid>
            <DailyCatchStatusContainer>
              <Usd1>{`Daily Catch: `}</Usd1>
              <Quantity>Status</Quantity>
            </DailyCatchStatusContainer>
          </Minbid>
          <Minbid>
            <DailyCatchStatusContainer>
              <Usd1>{`50 kg: `}</Usd1>
              <Quantity>Quantity</Quantity>
            </DailyCatchStatusContainer>
          </Minbid>
          <Minbid>
            <KgAvailableForContainer>
              <Usd1>30 kg:</Usd1>
              <Quantity> Available for bidding</Quantity>
            </KgAvailableForContainer>
          </Minbid>
          <Auctionlabel>
            <KgAvailableForContainer>
              Product Specification Form
            </KgAvailableForContainer>
          </Auctionlabel>
          <Auctionratings>
            <StarIcon loading="lazy" alt="" src="/star.svg" />
            <StarIcon alt="" src="/star.svg" />
            <StarIcon alt="" src="/star.svg" />
            <StarIcon1 alt="" src="/star-3.svg" />
            <StarIcon1 alt="" src="/star-3.svg" />
          </Auctionratings>
        </Auctiondetails>
      </Cardinner>
    </HomeauctioncardRoot>
  );
};

HomeAuctionCard.propTypes = {
  className: PropTypes.string,
  image33: PropTypes.string,

  /** Style props */
  propBackgroundImage: PropTypes.any,
};

export default HomeAuctionCard;
