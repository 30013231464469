import styled from "styled-components";
import PropTypes from "prop-types";

const MuaTnGc = styled.h1`
  margin: 0;
  width: 616px;
  position: relative;
  font-size: inherit;
  line-height: 39px;
  font-weight: 600;
  font-family: inherit;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    font-size: var(--font-size-9xl);
    line-height: 31px;
  }
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-2xl);
    line-height: 23px;
  }
`;
const Buyfromsourcetitle = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-9xs) 0px;
  box-sizing: border-box;
  max-width: 100%;
`;
const FarmProductsSvgrepoCom1Icon = styled.img`
  height: 42px;
  width: 42px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
`;
const Iconblock = styled.div`
  height: 72px;
  width: 72px;
  border-radius: var(--br-61xl);
  background-color: var(--color-gray-600);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini);
  box-sizing: border-box;
`;
const ChUMi = styled.div`
  position: relative;
  line-height: 22px;
  font-weight: 600;
  display: inline-block;
  min-width: 124px;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 18px;
  }
`;
const Icontitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-6xl) 0px;
`;
const Sourceiconblock = styled.div`
  flex: 0.9207;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px var(--padding-28xl) 0px 0px;
  gap: var(--gap-smi);
  @media screen and (max-width: 450px) {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const MarketSvgrepoCom1Icon = styled.img`
  width: 42px;
  height: 42px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
`;
const Iconblock1 = styled.div`
  height: 72px;
  width: 72px;
  border-radius: var(--br-81xl);
  background-color: var(--color-gray-600);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini);
  box-sizing: border-box;
`;
const ThngLi = styled.div`
  position: relative;
  line-height: 22px;
  font-weight: 600;
  display: inline-block;
  min-width: 110px;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 18px;
  }
`;
const Icontitle1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-6xl) 0px;
`;
const Sourceiconblock1 = styled.div`
  flex: 0.859;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px var(--padding-42xl) 0px 0px;
  gap: var(--gap-smi);
  @media screen and (max-width: 450px) {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const FrameIcon = styled.img`
  width: 42px;
  height: 42px;
  position: relative;
`;
const Iconblock2 = styled.div`
  height: 72px;
  width: 72px;
  border-radius: var(--br-61xl);
  background-color: var(--color-gray-600);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini);
  box-sizing: border-box;
`;
const OcopFarmer = styled.div`
  position: relative;
  line-height: 22px;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 18px;
  }
`;
const Sourceiconblock2 = styled.div`
  flex: 0.9604;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px var(--padding-19xl) 0px 0px;
  gap: var(--gap-smi);
`;
const VectorIcon = styled.img`
  width: 45px;
  height: 41px;
  position: relative;
`;
const Iconblock3 = styled.div`
  height: 72px;
  width: 72px;
  border-radius: var(--br-61xl);
  background-color: var(--color-gray-600);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) var(--padding-smi);
  box-sizing: border-box;
`;
const Sourceiconblock3 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px var(--padding-10xl) 0px 0px;
  gap: var(--gap-smi);
`;
const GroupIcon = styled.img`
  width: 55.56%;
  height: 55.56%;
  position: absolute;
  margin: 0 !important;
  top: 22.22%;
  right: 22.22%;
  bottom: 22.22%;
  left: 22.22%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const Iconblock4 = styled.div`
  height: 72px;
  width: 72px;
  border-radius: var(--br-61xl);
  background-color: var(--color-gray-600);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini);
  box-sizing: border-box;
  position: relative;
`;
const Icontitle2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-6xl) 0px;
`;
const Buysourceiconsgroup = styled.div`
  align-self: stretch;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-11xl);
  text-align: left;
  font-size: var(--regular-text-20-size);
  @media screen and (max-width: 500px) {
    justify-content: flex-start;
  }
`;
const BuyfromsourceiconblockRoot = styled.section`
  align-self: stretch;
  background-color: var(--salad);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-base);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-16xl);
  color: var(--neutral-white);
  font-family: var(--regular-text-20);
`;

const BuyFromSourceIconBlock = ({ className = "" }) => {
  return (
    <BuyfromsourceiconblockRoot className={className}>
      <Buyfromsourcetitle>
        <MuaTnGc>Mua Tận Gốc - Bán Tận Ngọn</MuaTnGc>
      </Buyfromsourcetitle>
      <Buysourceiconsgroup>
        <Sourceiconblock>
          <Iconblock>
            <FarmProductsSvgrepoCom1Icon
              alt=""
              src="/farmproductssvgrepocom-1.svg"
            />
          </Iconblock>
          <Icontitle>
            <ChUMi>Chợ Đầu Mối</ChUMi>
          </Icontitle>
        </Sourceiconblock>
        <Sourceiconblock1>
          <Iconblock1>
            <MarketSvgrepoCom1Icon alt="" src="/marketsvgrepocom-1.svg" />
          </Iconblock1>
          <Icontitle1>
            <ThngLi>Thương Lái</ThngLi>
          </Icontitle1>
        </Sourceiconblock1>
        <Sourceiconblock2>
          <Iconblock2>
            <FrameIcon alt="" src="/frame.svg" />
          </Iconblock2>
          <Icontitle1>
            <OcopFarmer>OCOP Farmer</OcopFarmer>
          </Icontitle1>
        </Sourceiconblock2>
        <Sourceiconblock3>
          <Iconblock3>
            <VectorIcon alt="" src="/vector-8.svg" />
          </Iconblock3>
          <Icontitle1>
            <OcopFarmer>Imported Food</OcopFarmer>
          </Icontitle1>
        </Sourceiconblock3>
        <Sourceiconblock>
          <Iconblock4>
            <GroupIcon alt="" src="/group-29.svg" />
          </Iconblock4>
          <Icontitle2>
            <OcopFarmer>Restaurant Supplier</OcopFarmer>
          </Icontitle2>
        </Sourceiconblock>
      </Buysourceiconsgroup>
    </BuyfromsourceiconblockRoot>
  );
};

BuyFromSourceIconBlock.propTypes = {
  className: PropTypes.string,
};

export default BuyFromSourceIconBlock;
