import styled from "styled-components";
import KioskDetails from "./KioskDetails";
import PropTypes from "prop-types";
import GoMarketButton from "./GoMarketButton";

const BinhDienMarket = styled.div`
  position: relative;
  line-height: 22px;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 18px;
  }
`;
const MarketStalls = styled.div`
  position: relative;
  line-height: 22px;
  font-weight: 600;
  display: inline-block;
  min-width: 125px;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 18px;
  }
`;
const MarketTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
  margin-bottom: 15px;
`;
const Farmer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  line-height: 27px;
  text-transform: capitalize;
  font-weight: 600;
`;
const Infocardtitle = styled.div`
  height: 27px;
  width: 249px;
  position: relative;
  display: none;
`;
const BinhDienMarket1 = styled.div`
  flex: 1;
  position: relative;
  font-size: var(--regular-14-size);
  line-height: 18px;
  text-transform: capitalize;
`;
const Infocardcontentfooter = styled.div`
  flex: 1;
  border-radius: var(--br-mini);
  background-color: var(--color-gray-700);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs);
`;
const Infocardcontentmain = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-xl);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-3xs);
`;
const Infocard = styled.div`
  height: 400px;
  border-radius: var(--br-mini);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url("/infocard@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
`;
const MapblockmainIcon = styled.img`
  height: 400px;
  flex: 1;
  border-radius: var(--br-mini);
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
`;
const Map1 = styled.div`
  border-radius: var(--br-xl);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const MarketstallimageIcon = styled.img`
  height: 154px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: 0;
  border-radius: 15px;
`;
const Region = styled.span``;
const SounthVn = styled.span`
  font-weight: 600;
`;
const Region1Container = styled.div`
  position: relative;
  line-height: 120%;
  display: inline-block;
  min-width: 121px;
`;
const MarketBinhContainer = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const Stallcardfloatinginfo = styled.div`
  width: 129px;
  margin: 0 !important;
  position: absolute;
  top: 7px;
  right: 13.5px;
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-700);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0px var(--padding-5xs) var(--padding-5xs);
  box-sizing: border-box;
  z-index: 1;
`;
const Stallcardfloatinginfo1 = styled.div`
  width: 129px;
  margin: 0 !important;
  position: absolute;
  top: 7px;
  right: -295px;
  border-radius: var(--br-3xs);
  background-color: var(--color-gray-700);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) 0px var(--padding-5xs) var(--padding-5xs);
  box-sizing: border-box;
  z-index: 1;
`;
const MarketstallimageParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
`;
const Stallcard = styled.div`
  border-radius: var(--br-xl);
  background-color: var(--light-gray);
  border: 1px solid var(--gray-light2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-lgi) var(--padding-smi);
  gap: var(--gap-base);
  font-size: var(--semibold-12-size);
  min-width: 285px;
`;
const MarketstallimageIcon1 = styled.img`
  height: 154px;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  border-radius: 15px;
`;
const MarketstallimageWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Stallcard1 = styled.div`
  border-radius: var(--br-xl);
  background-color: var(--light-gray);
  border: 1px solid var(--gray-light2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-lg) var(--padding-lgi) var(--padding-smi);
  gap: var(--gap-base);
  height: 100%;
`;
const MarketDetails = styled.div`
  ${"" /* align-self: stretch; */}
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-lgi-7);
  ${"" /* grid-template-columns: repeat(4, minmax(217px, 1fr)); */}
  font-size: var(--regular-text-15-size);
  @media screen and (max-width: 1125px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`;
const FrameChild = styled.div`
  height: 12px;
  width: 12px;
  position: relative;
  border-radius: 50%;
  background-color: var(--salad);
`;
const FrameItem = styled.div`
  height: 12px;
  width: 12px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-gainsboro-100);
`;
const EllipseParent = styled.div`
  display: none;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const GoToTheMarketPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0px 0px;
`;
const ArrowArrowLeftLg = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
`;
const Gomarketbutton1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  color: var(--salad);
`;

const Div = styled.div`
  overflow-x: scroll;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
const Row = styled.div`
  display: inline-grid;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  grid-template-columns: repeat(2, minmax(285px, 300px));
  gap: 20px;
  overflow-x: scroll;
`;

const BinhdienmarketsectionRoot = styled.section`
  align-self: stretch;
  background-color: var(--neutral-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-51xl) var(--padding-93xl);
  box-sizing: border-box;
  gap: var(--gap-mid);
  max-width: 100%;
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--color-gray-200);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 800px) {
    padding: var(--padding-26xl) var(--padding-37xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;

const BinhDienMarketSection = ({ className = "" }) => {
  return (
    <BinhdienmarketsectionRoot className={className}>
      <MarketDetails>
        <Div>
          <MarketTitle>
            <BinhDienMarket>Binh Dien Market</BinhDienMarket>
          </MarketTitle>
          <Row>
            <Infocard>
              <Infocardcontentmain>
                <Infocardcontentfooter>
                  <Infocardtitle>
                    <Farmer>Farmer 1</Farmer>
                  </Infocardtitle>
                  <BinhDienMarket1>
                    Binh Dien Market is one of the largest agricultural food
                    markets in Ho Chi Minh City. This is also the only wholesale
                    market supplying seafood to the market here.
                  </BinhDienMarket1>
                </Infocardcontentfooter>
              </Infocardcontentmain>
            </Infocard>
            <Map1>
              <MapblockmainIcon
                loading="lazy"
                alt=""
                src="/mapblockmain@2x.png"
              />
            </Map1>
          </Row>
        </Div>
        <Div>
          <MarketTitle>
            <MarketStalls>Market stalls</MarketStalls>
          </MarketTitle>
          <Row>
            <Stallcard>
              <MarketstallimageParent>
                <MarketstallimageIcon
                  loading="lazy"
                  alt=""
                  src="/marketstallimage@2x.png"
                />
                <Stallcardfloatinginfo>
                  <Region1Container>
                    <Region>{`Region : `}</Region>
                    <SounthVn>1 Sounth VN</SounthVn>
                  </Region1Container>
                  <MarketBinhContainer>
                    <Region>{`Market : `}</Region>
                    <SounthVn>Binh Dien</SounthVn>
                  </MarketBinhContainer>
                </Stallcardfloatinginfo>
                <Stallcardfloatinginfo1>
                  <Region1Container>
                    <Region>{`Region : `}</Region>
                    <SounthVn>1 Sounth VN</SounthVn>
                  </Region1Container>
                  <MarketBinhContainer>
                    <Region>{`Market : `}</Region>
                    <SounthVn>Binh Dien</SounthVn>
                  </MarketBinhContainer>
                </Stallcardfloatinginfo1>
              </MarketstallimageParent>
              <KioskDetails
                uTLUOMKiosk="UT LUOM Kiosk"
                voVanTeoEm=" Vo Van Teo Em"
                pork=" Pork"
              />
            </Stallcard>
            <Stallcard>
              <MarketstallimageParent>
                <MarketstallimageIcon
                  loading="lazy"
                  alt=""
                  src="/marketstallimage-1@2x.png"
                />
                <Stallcardfloatinginfo>
                  <Region1Container>
                    <Region>{`Region : `}</Region>
                    <SounthVn>1 Sounth VN</SounthVn>
                  </Region1Container>
                  <MarketBinhContainer>
                    <Region>{`Market : `}</Region>
                    <SounthVn>Binh Dien</SounthVn>
                  </MarketBinhContainer>
                </Stallcardfloatinginfo>
                <Stallcardfloatinginfo1>
                  <Region1Container>
                    <Region>{`Region : `}</Region>
                    <SounthVn>1 Sounth VN</SounthVn>
                  </Region1Container>
                  <MarketBinhContainer>
                    <Region>{`Market : `}</Region>
                    <SounthVn>Binh Dien</SounthVn>
                  </MarketBinhContainer>
                </Stallcardfloatinginfo1>
              </MarketstallimageParent>
              <KioskDetails
                uTLUOMKiosk="Kim Tien's Kiosk"
                voVanTeoEm=" Nguyen Thi Kim Tien"
                pork=" Miscellaneous"
              />
            </Stallcard>
          </Row>
        </Div>
      </MarketDetails>
      <EllipseParent>
        <FrameChild />
        <FrameItem />
      </EllipseParent>
      <GoMarketButton goToTheMarketPage="Go to the market page" />
    </BinhdienmarketsectionRoot>
  );
};

BinhDienMarketSection.propTypes = {
  className: PropTypes.string,
};

export default BinhDienMarketSection;
