import { useCallback } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  InputAdornment,
} from "@mui/material";
import styled from "styled-components";
import MarketLists from "./MarketLists";
import GoMarketButton from "./GoMarketButton";
import PropTypes from "prop-types";

const FindingTheWholesales = styled.div`
  position: relative;
  letter-spacing: -0.02em;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
`;
const FindSourcing = styled.div`
  position: relative;
  line-height: 22px;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 18px;
  }
`;
const Titleblock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px var(--padding-603xl) 0px 0px;
  box-sizing: border-box;
  gap: var(--gap-22xl);
  max-width: 100%;
  @media screen and (max-width: 1350px) {
    flex-wrap: wrap;
    padding-right: var(--padding-292xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 800px) {
    gap: var(--gap-xl);
    padding-right: var(--padding-136xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const Findmarkettitlesection = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
`;
const Image18Icon = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  @media screen and (max-width: 450px) {
    object-fit: contain;
  }
`;
const Mapblock = styled.div`
  width: 246px;
  height: 388px;
  margin: 0 !important;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: var(--br-mini);
  background-color: var(--neutral-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-31xl);
  box-sizing: border-box;
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;
const Findinwholesalesmapsection = styled.div`
  height: 388px;
  width: 260px;
  border-radius: var(--br-mini);
  overflow-x: auto;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-sm) 0px 0px;
  box-sizing: border-box;
  position: relative;
  @media screen and (max-width: 450px) {
    width: 95%;
    background: (--white);
  }
`;
const MekongDelta = styled.p`
  margin: 0;
`;
const MekongDeltaSouthContainer = styled.span`
  width: 100%;
`;
const MekongDeltaSouthContainer1 = styled.div`
  width: 116px;
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
`;
const Mekongdeltaheader = styled.div`
  border-radius: var(--br-xl) var(--br-xl) 0px 0px;
  background-color: var(--color-darkorange);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs-5) var(--padding-56xl);
  @media screen and (max-width: 450px) {
    ${
      "" /* padding-left: var(--padding-xl);
    padding-right: var(--padding-xl); */
    }
    box-sizing: border-box;
    font-size: 14px;
  }
`;
const CetralVillagesCentralContainer = styled.div`
  position: relative;
  line-height: 24px;
`;
const Cetralvillagesheader = styled.div`
  border-radius: var(--br-xl) var(--br-xl) 0px 0px;
  background-color: var(--gray-light2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs-5) var(--padding-51xl);
  color: var(--medium-gray);
  @media screen and (max-width: 450px) {
    ${
      "" /* padding-left: var(--padding-xl);
    padding-right: var(--padding-xl); */
    }
    box-sizing: border-box;
    font-size: 14px;
  }
`;
const Findingwholesalesmarketheading = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-3xs) 0px 0px;
  gap: var(--gap-3xs);
  @media screen and (max-width: 800px) {
    ${"" /* flex-wrap: wrap; */}
  }
`;
const TopListOf = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 22px;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 18px;
  }
`;
const Topwholesalemarketlists = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-27xl);
  font-size: var(--regular-text-15-size);
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    gap: var(--gap-4xl);
  }
`;
const Toplistwholesalemarkets = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const Inputlabel1 = styled(InputLabel)``;
const AuctionLowestPriceAtTheSo = styled.p``;
const Select1 = styled(Select)``;
const Formhelpertext1 = styled(FormHelperText)``;
const Lowestpricesourcebutton = styled.div`
  width: 324px;
  height: 47px;
  font-family: var(--regular-text-20);
  font-weight: 600;
  font-size: var(--regular-text-15-size);
  color: var(--salad);
  max-width: 100%;
  cursor: pointer;
  padding-left: 20px;
  border: 2px solid var(--salad);
  border-radius: 50px;
  display: flex;
  align-items: center;
`;
const Wholesalemarketsfooterbuttonbl = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  max-width: 100%;
`;
const Marketmekongcetralcontentblock = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
  max-width: 100%;
`;
const Mekongcetralregioncontentblock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini) var(--padding-3xs);
  box-sizing: border-box;
  max-width: 100%;
`;
const Borderright = styled.div`
  height: 321px;
  width: 2px;
  position: relative;
  background-color: var(--gray-light2);
  transform: rotate(180deg);
  display: none;
`;
const Findingwholesalemarketblock = styled.div`
  align-self: stretch;
  border-radius: 0px 0px 0px var(--br-mini);
  background-color: var(--neutral-white);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--color-gray-200);
`;
const Mekongcetralwholesalemarketblo = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
`;
const Leftfindingmarketssection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 555px;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    ${"" /* min-width: 100%; */}
  }
`;
const RedRiverDeltaContainer = styled.div`
  width: 128px;
  position: relative;
  line-height: 24px;
  display: flex;
  align-items: center;
`;
const Redriverdeltaheader = styled.div`
  align-self: stretch;
  border-radius: var(--br-xl) var(--br-xl) 0px 0px;
  background-color: var(--gray-light2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs-5) var(--padding-xl);
  @media screen and (max-width: 450px) {
    ${
      "" /* padding-left: var(--padding-xl);
    padding-right: var(--padding-xl); */
    }
    box-sizing: border-box;
    font-size: 14px;
  }
`;
const BaRiaVungTau = styled.li``;
const BaRiaVungTauSeafood = styled.ul`
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-xl);
`;
const BaRiaVungTauContainer = styled.div`
  position: relative;
  line-height: 24px;
  text-transform: capitalize;
`;
const Marketlists1 = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-xl) var(--padding-sm) 0px;
  gap: var(--gap-8xs);
  font-size: var(--regular-text-15-size);
`;
const Brockercontentblock = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) var(--padding-mini);
  gap: var(--gap-base);
`;
const Redriverdeltacontent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const Redriverdeltacontentblock = styled.div`
  align-self: stretch;
  border-radius: 0px 0px var(--br-mini) 0px;
  background-color: var(--neutral-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--color-gray-200);
`;
const Redriverdeltasuppliersourceroc = styled.div`
  flex: 0.5315;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-241xl) 0px 0px;
  box-sizing: border-box;
  min-width: 554px;
  max-width: 100%;
  color: var(--medium-gray);

  @media screen and (max-width: 800px) {
    ${"" /* padding-right: var(--padding-111xl); */}
    box-sizing: border-box;
    ${"" /* min-width: 100%; */}
  }
  @media screen and (max-width: 450px) {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
    flex: 1;
  }
`;
const Findingwholesalesmarketssource = styled.div`
  width: 1110px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  max-width: 100%;
  @media screen and (max-width: 1125px) {
    ${"" /* flex-wrap: wrap; */}
    width: 100%;
    overflow-x: scroll;
  }
`;
const Findmarketmainrowsection = styled.div`
  ${"" /* align-self: stretch; */}
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  row-gap: 20px;
  max-width: 100%;
  gap: var(--gap-0);
  text-align: center;
  font-size: var(--font-size-mid);
  color: var(--neutral-white);
  @media screen and (max-width: 1350px) {
    flex-wrap: wrap;
    gap: 30px;
  }
`;
const Findingwholesalesmarketinregio1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  max-width: 100%;
  @media screen and (max-width: 800px) {
    gap: var(--gap-xl);
  }
`;
const Mainfindingmarketinregionblock = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 0px var(--padding-51xl);
  box-sizing: border-box;
  gap: var(--gap-21xl);
  max-width: 100%;
  @media screen and (max-width: 800px) {
    gap: var(--gap-xl);
    padding-left: var(--padding-16xl);
    box-sizing: border-box;
  }
`;
const FindingwholesalesmarketinregioRoot = styled.section`
  align-self: stretch;
  background-color: var(--light-gray);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-51xl) 0px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--color-gray-200);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 800px) {
    padding-top: var(--padding-26xl);
    padding-bottom: var(--padding-26xl);
    box-sizing: border-box;
  }
`;

const ArrowArrowLeftLg = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
`;

const FindingWholesalesMarketInRegio = ({ className = "" }) => {
  const onLowestPriceSourceButtonClick = useCallback(() => {
    // Please sync "Homepage" to the project
  }, []);

  return (
    <FindingwholesalesmarketinregioRoot className={className}>
      <Mainfindingmarketinregionblock>
        <Findingwholesalesmarketinregio1>
          <Findmarkettitlesection>
            <Titleblock>
              <FindingTheWholesales>
                Finding the WholeSales Market in Region
              </FindingTheWholesales>
              <FindSourcing>Find Sourcing</FindSourcing>
            </Titleblock>
          </Findmarkettitlesection>
          <Findmarketmainrowsection>
            <Findinwholesalesmapsection>
              <Mapblock>
                <Image18Icon loading="lazy" alt="" src="/image-18@2x.png" />
              </Mapblock>
            </Findinwholesalesmapsection>
            <Findingwholesalesmarketssource>
              <Leftfindingmarketssection>
                <Mekongcetralwholesalemarketblo>
                  <Findingwholesalesmarketheading>
                    <Mekongdeltaheader>
                      <MekongDeltaSouthContainer1>
                        <MekongDeltaSouthContainer>
                          <MekongDelta>{`Mekong Delta `}</MekongDelta>
                          <MekongDelta>(South region)</MekongDelta>
                        </MekongDeltaSouthContainer>
                      </MekongDeltaSouthContainer1>
                    </Mekongdeltaheader>
                    <Cetralvillagesheader>
                      <CetralVillagesCentralContainer>
                        <MekongDelta> Cetral Villages</MekongDelta>
                        <MekongDelta>(Central region)</MekongDelta>
                      </CetralVillagesCentralContainer>
                    </Cetralvillagesheader>
                  </Findingwholesalesmarketheading>
                  <Findingwholesalemarketblock>
                    <Mekongcetralregioncontentblock>
                      <Marketmekongcetralcontentblock>
                        <Toplistwholesalemarkets>
                          <TopListOf>Top List of Wholesale Markets</TopListOf>
                          <Topwholesalemarketlists>
                            <MarketLists />
                            <MarketLists />
                          </Topwholesalemarketlists>
                        </Toplistwholesalemarkets>
                        <Wholesalemarketsfooterbuttonbl>
                          <Lowestpricesourcebutton
                            variant="standard"
                            sx={{
                              borderColor: "#7ac143",
                              borderStyle: "SOLID",
                              borderTopWidth: "1px",
                              borderRightWidth: "1px",
                              borderBottomWidth: "1px",
                              borderLeftWidth: "1px",
                              backgroundColor: "#fff",
                              borderRadius: "23px",
                              width: "60.5607476635514%",
                              height: "47px",
                              m: 0,
                              p: 0,
                              "& .MuiInputBase-root": {
                                m: 0,
                                p: 0,
                                minHeight: "47px",
                                justifyContent: "center",
                                display: "inline-flex",
                              },
                              "& .MuiInputLabel-root": {
                                m: 0,
                                p: 0,
                                minHeight: "47px",
                                display: "inline-flex",
                              },
                              "& .MuiMenuItem-root": {
                                m: 0,
                                p: 0,
                                height: "47px",
                                display: "inline-flex",
                              },
                              "& .MuiSelect-select": {
                                m: 0,
                                p: 0,
                                height: "47px",
                                alignItems: "center",
                                display: "inline-flex",
                              },
                              "& .MuiInput-input": { m: 0, p: 0 },
                              "& .MuiInputBase-input": {
                                color: "#7ac143",
                                fontSize: 15,
                                fontWeight: "Semi Bold",
                                fontFamily: "Inter",
                                textAlign: "left",
                                p: "0 !important",
                                marginLeft: "20px",
                              },
                            }}
                            onClick={onLowestPriceSourceButtonClick}
                          >
                            <AuctionLowestPriceAtTheSo>
                              Auction Lowest Price at the Source
                            </AuctionLowestPriceAtTheSo>
                            <ArrowArrowLeftLg
                              alt=""
                              src="/Arrow/Arrow_Left_LG2.svg"
                            />
                          </Lowestpricesourcebutton>
                        </Wholesalemarketsfooterbuttonbl>
                      </Marketmekongcetralcontentblock>
                    </Mekongcetralregioncontentblock>
                    <Borderright />
                  </Findingwholesalemarketblock>
                </Mekongcetralwholesalemarketblo>
              </Leftfindingmarketssection>
              <Redriverdeltasuppliersourceroc>
                <Redriverdeltaheader>
                  <RedRiverDeltaContainer>
                    <MekongDeltaSouthContainer>
                      <MekongDelta>{`Red River Delta `}</MekongDelta>
                      <MekongDelta>(North region)</MekongDelta>
                    </MekongDeltaSouthContainer>
                  </RedRiverDeltaContainer>
                </Redriverdeltaheader>
                <Redriverdeltacontentblock>
                  <Redriverdeltacontent>
                    <Brockercontentblock>
                      <TopListOf>Supplier, Sourcer, OCOP Broker</TopListOf>
                      <Marketlists1>
                        <BaRiaVungTauContainer>
                          <BaRiaVungTauSeafood>
                            <BaRiaVungTau>
                              Ba Ria-Vung Tau (Seafood)
                            </BaRiaVungTau>
                          </BaRiaVungTauSeafood>
                        </BaRiaVungTauContainer>
                        <BaRiaVungTauContainer>
                          <BaRiaVungTauSeafood>
                            <BaRiaVungTau>
                              City. Ho Chi Minh (Meat)
                            </BaRiaVungTau>
                          </BaRiaVungTauSeafood>
                        </BaRiaVungTauContainer>
                        <BaRiaVungTauContainer>
                          <BaRiaVungTauSeafood>
                            <BaRiaVungTau>Tien Giang (Watermelon)</BaRiaVungTau>
                          </BaRiaVungTauSeafood>
                        </BaRiaVungTauContainer>
                        <BaRiaVungTauContainer>
                          <BaRiaVungTauSeafood>
                            <BaRiaVungTau>Ben Tre (Coconut)</BaRiaVungTau>
                          </BaRiaVungTauSeafood>
                        </BaRiaVungTauContainer>
                        <BaRiaVungTauContainer>
                          <BaRiaVungTauSeafood>
                            <BaRiaVungTau>
                              Dong Thap (Fresh Flowers)
                            </BaRiaVungTau>
                          </BaRiaVungTauSeafood>
                        </BaRiaVungTauContainer>
                        <BaRiaVungTauContainer>
                          <BaRiaVungTauSeafood>
                            <BaRiaVungTau>Kien Giang (Seafood)</BaRiaVungTau>
                          </BaRiaVungTauSeafood>
                        </BaRiaVungTauContainer>
                        <BaRiaVungTauContainer>
                          <BaRiaVungTauSeafood>
                            <BaRiaVungTau>Hau Giang (Rice)</BaRiaVungTau>
                          </BaRiaVungTauSeafood>
                        </BaRiaVungTauContainer>
                        <BaRiaVungTauContainer>
                          <BaRiaVungTauSeafood>
                            <BaRiaVungTau>
                              Ca Mau (Dried foods, Seafood)
                            </BaRiaVungTau>
                          </BaRiaVungTauSeafood>
                        </BaRiaVungTauContainer>
                      </Marketlists1>
                    </Brockercontentblock>
                  </Redriverdeltacontent>
                </Redriverdeltacontentblock>
              </Redriverdeltasuppliersourceroc>
            </Findingwholesalesmarketssource>
          </Findmarketmainrowsection>
        </Findingwholesalesmarketinregio1>
        <GoMarketButton
          propPadding="0px 20px 0px 0px"
          goToTheMarketPage="Go to the market page"
        />
      </Mainfindingmarketinregionblock>
    </FindingwholesalesmarketinregioRoot>
  );
};

FindingWholesalesMarketInRegio.propTypes = {
  className: PropTypes.string,
};

export default FindingWholesalesMarketInRegio;
