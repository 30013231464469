import styled from "styled-components";
import PropTypes from "prop-types";

const Icon1 = styled.img`
  height: 54px;
  width: 54px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
`;
const Iconblock = styled.div`
  width: 140px;
  height: 140px;
  border-radius: var(--br-61xl);
  background-color: var(--light-gray);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini);
  box-sizing: border-box;
  @media screen and (max-width: 800px) {
    width: 100px;
    height: 100px;
  }
  @media screen and (max-width: 450px) {
    width: 80px;
    height: 80px;
  }
`;
const DriedFoods = styled.div`
  width: 100%;
  height: 100%;
  line-height: 22px;
  font-weight: 600;
  font-size: var(--font-size-base);
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
  @media screen and (max-width: 450px) {
    font-size: 12px;
    font-weight: 500;
  }
  min-width: ${(p) => p.propMinWidth};
`;
const Cattitle = styled.div`
  height: 22px;
  width: 100%;
  text-align: center;
`;
const CatblockRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px var(--padding-10xs) 0px 0px;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--color-gray-200);
  font-family: var(--regular-text-20);
`;

const CatBlock = ({
  className = "",
  icon,
  propWidth,
  driedFoods,
  propMinWidth,
}) => {
  return (
    <CatblockRoot className={className}>
      <Iconblock>
        <Icon1 alt="" src={icon} />
      </Iconblock>
      <Cattitle propWidth={propWidth}>
        <DriedFoods propMinWidth={propMinWidth}>{driedFoods}</DriedFoods>
      </Cattitle>
    </CatblockRoot>
  );
};

CatBlock.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  driedFoods: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
  propMinWidth: PropTypes.any,
};

export default CatBlock;
