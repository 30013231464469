import styled from "styled-components";
import NowButton from "./NowButton";
import PropTypes from "prop-types";

const P = styled.span`
  text-transform: uppercase;
`;
const OstOfferAs = styled.span`
  text-transform: lowercase;
`;
const PostOfferAsContainer = styled.h2`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 130%;
  font-weight: 600;
  font-family: inherit;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-lgi);
    line-height: 25px;
  }
`;
const AsASeller = styled.div`
  align-self: stretch;
  height: 154px;
  position: relative;
  font-size: var(--font-size-mid);
  line-height: 24px;
  font-family: var(--regular-text-20);
  display: inline-block;
`;
const Topcontent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
`;
const Footercontent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px var(--padding-48xl) 0px 0px;
  gap: var(--gap-3xl);
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    padding-right: var(--padding-14xl);
    box-sizing: border-box;
  }
`;
const Contentblock = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-31xl);
  gap: var(--gap-11xl);
  @media screen and (max-width: 800px) {
    gap: var(--gap-mini);
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding-top: var(--padding-13xl);
    padding-bottom: var(--padding-13xl);
    box-sizing: border-box;
  }
`;
const StartauctioncardRoot = styled.div`
  flex: 1;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-800);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 448px;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-5xl);
  color: var(--neutral-white);
  font-family: var(--font-ibm-plex-sans);
  @media screen and (max-width: 800px) {
    min-width: 100%;
  }
`;

const StartAuctionCard = ({
  className = "",
  oSTOFFERASASELLER,
  asASellerPostOffersForThe,
  nowButton,
  nowButton1,
}) => {
  return (
    <StartauctioncardRoot className={className}>
      <Contentblock>
        <Topcontent>
          <PostOfferAsContainer>
            <P>P</P>
            <OstOfferAs>{oSTOFFERASASELLER}</OstOfferAs>
          </PostOfferAsContainer>
          <AsASeller>{asASellerPostOffersForThe}</AsASeller>
        </Topcontent>
        <Footercontent>
          <NowButton
            nowButton={nowButton}
            propWidth="unset"
            propFlex="1"
            propMinWidth="145px"
            background="#FB811F"
            color="#fff"
          />
          <NowButton
            nowButton={nowButton1}
            propWidth="186px"
            propFlex="unset"
            propMinWidth="unset"
          />
        </Footercontent>
      </Contentblock>
    </StartauctioncardRoot>
  );
};

StartAuctionCard.propTypes = {
  className: PropTypes.string,
  oSTOFFERASASELLER: PropTypes.string,
  asASellerPostOffersForThe: PropTypes.string,
  nowButton: PropTypes.string,
  nowButton1: PropTypes.string,
};

export default StartAuctionCard;
