import styled from "styled-components";
import PropTypes from "prop-types";

const GoToThe = styled.div`
  position: relative;
  line-height: 22px;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 18px;
  }
`;
const ArrowArrowLeftLg = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
`;
const GomarketbuttonRoot = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--salad);
  font-family: var(--regular-text-20);
  padding: ${(p) => p.propPadding};
`;

const GoMarketButton = ({ className = "", propPadding, goToTheMarketPage }) => {
  return (
    <GomarketbuttonRoot propPadding={propPadding} className={className}>
      <GoToThe>{goToTheMarketPage}</GoToThe>
      <ArrowArrowLeftLg alt="" src="/Arrow/Arrow_Left_LG2.svg" />
    </GomarketbuttonRoot>
  );
};

GoMarketButton.propTypes = {
  className: PropTypes.string,
  goToTheMarketPage: PropTypes.string,

  /** Style props */
  propPadding: PropTypes.any,
};

export default GoMarketButton;
