import React from "react";
import styled from "styled-components";
import HomeAuctionCard from "./HomeAuctionCard";

const Auctionscarosel = styled.div`
  flex: 1;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  max-width: 100%;
`;

const FeaturedAuctions = styled.div`
  width: 1250px;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
`;

const FeaturedauctionsChild = styled.div`
  width: 907px;
  height: 2px;
  position: relative;
  background-color: var(--color-whitesmoke-200);
  display: none;
  max-width: 100%;
`;

const AuctionCaroselContainer = styled.div`
  width: 1250px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-smi);
  box-sizing: border-box;
  max-width: 100%;
`;

const ScrollindicatorChild = styled.div`
  height: 12px;
  width: 12px;
  position: relative;
  border-radius: 50%;
  background-color: var(--neutral-white);
`;
const ScrollindicatorItem = styled.div`
  height: 12px;
  width: 12px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-gray-200);
`;
const Scrollindicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const PageIndicator = styled.div`
  width: 1250px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  max-width: 100%;
`;
function HomeAuctions({
  backgroundColor = "var(--blue)",
  homeAuctionTitle = "Featured Auctions",
}) {
  const Featuredauctions = styled.section`
    align-self: stretch;
    background-color: ${backgroundColor};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: var(--padding-61xl) var(--padding-xl);
    box-sizing: border-box;
    gap: var(--gap-11xl);
    max-width: 100%;
    text-align: left;
    font-size: var(--regular-text-20-size);
    color: var(--neutral-white);
    font-family: var(--regular-text-20);
    @media screen and (max-width: 800px) {
      padding-top: var(--padding-33xl);
      padding-bottom: var(--padding-33xl);
      box-sizing: border-box;
    }
  `;
  return (
    <Featuredauctions>
      <FeaturedAuctions>{homeAuctionTitle}</FeaturedAuctions>
      <AuctionCaroselContainer>
        <Auctionscarosel>
          <HomeAuctionCard image33="/image-33@2x.png" />
          <HomeAuctionCard
            propBackgroundImage="url('/image-331@2x.png')"
            image33="/image-331@2x.png"
          />
          <HomeAuctionCard
            propBackgroundImage="url('/image-33@2x.png')"
            image33="/image-33@2x.png"
          />
          <HomeAuctionCard
            propBackgroundImage="url('/image-331@2x.png')"
            image33="/image-331@2x.png"
          />
        </Auctionscarosel>
      </AuctionCaroselContainer>
      <PageIndicator>
        <Scrollindicator>
          <ScrollindicatorChild />
          <ScrollindicatorItem />
        </Scrollindicator>
      </PageIndicator>
      <FeaturedauctionsChild />
    </Featuredauctions>
  );
}

export default HomeAuctions;
