import styled from "styled-components";
import VisitButton from "./VisitButton";
import PropTypes from "prop-types";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const UtLuomKiosk = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: capitalize;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
`;
const Trader = styled.span``;
const VoVanTeo = styled.span`
  color: var(--color-black);
`;
const TraderVoVanTeoContainer = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 18px;
  text-transform: capitalize;
`;
const LocationPinIcon = styled.img`
  height: 13px;
  width: 9px;
  position: relative;
`;
const Icon1 = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-12xs) var(--padding-10xs);
  box-sizing: border-box;
  width: 15px;
  height: 15px;
`;
const NguyenVanLinh = styled.div`
  height: 46px;
  flex: 1;
  position: relative;
  line-height: 18px;
  text-transform: capitalize;
  display: inline-block;
`;
const AddressBlock = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Icon2 = styled.img`
  height: 15px;
  width: 15px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
`;
const Separator = styled.div`
  position: relative;
  line-height: 18px;
  text-transform: capitalize;
  display: inline-block;
  min-width: 88px;
`;
const EmptyBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-10xs);
`;
const TraderAddress = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  color: var(--color-black);
`;
const TraderInfo = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
`;
const ViewTheVideo = styled.div`
  position: relative;
  line-height: 120%;
  font-weight: 600;
  display: inline-block;
  min-width: 85px;
`;
const VideoButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-2xs) 0px 0px;
  height: ${(p) => p.propHeight};
`;
const StoreButtons = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-4xl-3);
  font-size: var(--semibold-12-size);
  color: var(--salad);
`;
const KioskInfo = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-12xs);
  font-size: var(--regular-14-size);
  color: var(--color-gray-200);
  font-family: var(--regular-text-20);
`;
const KioskDetailsRoot = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--color-black);
  font-family: var(--font-ibm-plex-sans);
`;

const KioskDetails = ({
  className = "",
  uTLUOMKiosk,
  voVanTeoEm,
  pork,
  propHeight,
}) => {
  return (
    <KioskDetailsRoot className={className}>
      <UtLuomKiosk>{uTLUOMKiosk}</UtLuomKiosk>
      <KioskInfo>
        <TraderInfo>
          <TraderVoVanTeoContainer>
            <Trader>Trader:</Trader>
            <VoVanTeo>{voVanTeoEm}</VoVanTeo>
          </TraderVoVanTeoContainer>
          <TraderVoVanTeoContainer>
            <Trader>Business:</Trader>
            <VoVanTeo>{pork}</VoVanTeo>
          </TraderVoVanTeoContainer>
          <TraderAddress>
            <AddressBlock>
              <Icon1>
                <LocationPinIcon alt="" src="/vector-10.svg" />
              </Icon1>
              <NguyenVanLinh>
                Nguyen Van Linh Avenue, Quarter 6, Ward 7, District 8, City. HCM
              </NguyenVanLinh>
            </AddressBlock>
            <EmptyBlock>
              <LocalPhoneIcon />
              <Separator>0978.511.522</Separator>
            </EmptyBlock>
          </TraderAddress>
        </TraderInfo>
        <StoreButtons>
          <VisitButton />
          <VideoButton propHeight={propHeight}>
            <ViewTheVideo>view the video</ViewTheVideo>
          </VideoButton>
        </StoreButtons>
      </KioskInfo>
    </KioskDetailsRoot>
  );
};

KioskDetails.propTypes = {
  className: PropTypes.string,
  uTLUOMKiosk: PropTypes.string,
  voVanTeoEm: PropTypes.string,
  pork: PropTypes.string,

  /** Style props */
  propHeight: PropTypes.any,
};

export default KioskDetails;
