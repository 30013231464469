import styled from "styled-components";
import CatBlock from "./CatBlock";
import PropTypes from "prop-types";

const ExploreCategory = styled.div`
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
`;
const Titleblock = styled.div`
  width: 1216px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
`;

const Catblocksliderrow = styled.div`
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-17xl);
  max-width: 100%;
  @media screen and (max-width: 800px) {
    gap: var(--gap-lg);
  }
`;
const ExplorecategorysectionRoot = styled.section`
  align-self: stretch;
  background-color: var(--neutral-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-11xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--color-gray-200);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 450px) {
    padding-top: var(--padding-33xl);
    padding-bottom: var(--padding-33xl);
    box-sizing: border-box;
  }
`;

const ExploreCategorySection = ({ className = "" }) => {
  return (
    <ExplorecategorysectionRoot className={className}>
      <Titleblock>
        <ExploreCategory>Explore CATEGORY</ExploreCategory>
      </Titleblock>
      <Catblocksliderrow>
        <CatBlock icon="/icon.svg" driedFoods="Daily Catch" />
        <CatBlock icon="/icon-1.svg" driedFoods="Fresh Harvest" />

        <CatBlock icon="/icon-2.svg" driedFoods="Dried Foods" />
        <CatBlock icon="/icon-3.svg" driedFoods="SeaFood" />
        <CatBlock icon="/icon-4.svg" driedFoods="Chicken" />
        <CatBlock icon="/icon-5.svg" driedFoods="Pork" />
        <CatBlock icon="/icon-6.svg" driedFoods="Beef " />
      </Catblocksliderrow>
    </ExplorecategorysectionRoot>
  );
};

ExploreCategorySection.propTypes = {
  className: PropTypes.string,
};

export default ExploreCategorySection;
