import styled from "styled-components";
import PropTypes from "prop-types";

const Smartbbee = styled.p`
  margin: 0;
`;
const SmartbbeeAgriauctionPlatformContainer = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 22px;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 18px;
  }
`;
const PlatformName = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0px 0px;
  box-sizing: border-box;
  max-width: 100%;
`;
const LogoIcon = styled.img`
  height: 60px;
  width: 130px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
  display: none;
`;
const Footerlogo = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  max-width: 100%;
  font-size: var(--regular-text-20-size);
  color: var(--color-whitesmoke-100);
  font-family: var(--regular-text-20);
`;
const Copyright = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 20px;
  white-space: pre-wrap;
`;
const AllRightsReserved = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 20px;
`;
const Copyright1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const SocialIcons = styled.img`
  height: 32px;
  width: 32px;
  position: relative;
  min-height: 32px;
`;
const IconsSocial = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
`;
const AppleIcon = styled.img`
  height: 21px;
  width: 18px;
  position: relative;
`;
const AppStoreIcon = styled.img`
  align-self: stretch;
  height: 14px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
`;
const PlayStore = styled.div`
  width: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs-5) 0px 0px;
  box-sizing: border-box;
`;
const AppIcons = styled.div`
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--light-gray);
  border: 1px solid var(--light-gray);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--padding-xs);
  gap: var(--gap-lg);
`;
const ObjectIcon = styled.img`
  height: 21px;
  width: 20px;
  position: relative;
  object-fit: cover;
  min-height: 21px;
`;
const GooglePlayIcon = styled.img`
  align-self: stretch;
  height: 15px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
`;
const GooglePlayWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-10xs) 0px 0px;
`;
const AppIcons1 = styled.div`
  flex: 1;
  border-radius: var(--br-5xs);
  background-color: var(--light-gray);
  border: 1px solid var(--light-gray);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-9xs) var(--padding-2xs);
  gap: var(--gap-sm);
`;
const StoreIcons = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const SocialBlock = styled.div`
  width: 294px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  @media screen and (max-width: 450px) {
    gap: var(--gap-xl);
  }
`;
const SocialMedia = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const FooterContent = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  min-width: 350px;
  max-width: 100%;
  @media screen and (max-width: 1350px) {
    flex: 1;
  }
  @media screen and (max-width: 800px) {
    min-width: 100%;
  }
  @media screen and (max-width: 450px) {
    gap: var(--gap-xl);
  }
`;
const Account = styled.div`
  flex: 1;
  position: relative;
  line-height: 28px;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
`;
const Titleblock = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ListItems = styled.div`
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--regular-14-size);
`;
const Col = styled.div`
  height: 331px;
  flex: 1;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
`;
const Home = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: var(--light-gray);
`;
const ListItems1 = styled.div`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--regular-14-size);
`;
const Col1 = styled.div`
  flex: 1;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-80xl);
  gap: var(--gap-5xl);
`;
const AboutUs = styled.a`
  text-decoration: none;
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: inherit;
`;
const ContactUs = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 20px;
  color: var(--neutral-white);
`;
const ListItems2 = styled.div`
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
  font-size: var(--regular-14-size);
  color: var(--light-gray);
`;
const Col2 = styled.div`
  height: 100%;
  width: 152.8px;
  margin: 0 !important;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 599.3px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
`;
const Linkssection = styled.div`
  flex: 1;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-28xl);
  min-width: 526px;
  max-width: 100%;
  font-size: var(--regular-text-20-size);
  color: var(--neutral-white);
  @media screen and (max-width: 1125px) {
    min-width: 100%;
  }
  @media screen and (max-width: 800px) {
    gap: var(--gap-4xl);
  }
`;
const FooterhomeRoot = styled.footer`
  align-self: stretch;
  background-color: var(--color-dimgray-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-45xl) var(--padding-146xl);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  max-width: 100%;
  z-index: 7;
  text-align: left;
  font-size: var(--regular-14-size);
  color: var(--light-gray);
  font-family: var(--font-ibm-plex-sans);
  @media screen and (max-width: 1350px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 800px) {
    padding-left: var(--padding-63xl);
    padding-right: var(--padding-63xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding: var(--padding-23xl) var(--padding-xl);
    box-sizing: border-box;
  }
`;

const FooterHome = ({ className = "" }) => {
  return (
    <FooterhomeRoot className={className}>
      <FooterContent>
        <Footerlogo>
          <PlatformName>
            <SmartbbeeAgriauctionPlatformContainer>
              <Smartbbee>SmartBbee</Smartbbee>
              <Smartbbee>AgriAuction</Smartbbee>
              <Smartbbee>Platform</Smartbbee>
            </SmartbbeeAgriauctionPlatformContainer>
          </PlatformName>
          <LogoIcon loading="lazy" alt="" src="/arrow--arrow-left-lg-41.svg" />
        </Footerlogo>
        <Copyright1>
          <Copyright>Copyright © 2024 Bbee Smart Market</Copyright>
          <AllRightsReserved>All rights reserved</AllRightsReserved>
        </Copyright1>
        <SocialMedia>
          <AllRightsReserved>Follow us:</AllRightsReserved>
          <SocialBlock>
            <IconsSocial>
              <SocialIcons loading="lazy" alt="" src="/social-icons.svg" />
              <SocialIcons alt="" src="/social-icons-1.svg" />
              <SocialIcons alt="" src="/social-icons-2.svg" />
            </IconsSocial>
            <StoreIcons>
              <AppIcons>
                <AppleIcon alt="" src="/apple.svg" />
                <PlayStore>
                  <AppStoreIcon loading="lazy" alt="" src="/app-store.svg" />
                </PlayStore>
              </AppIcons>
              <AppIcons1>
                <ObjectIcon alt="" src="/object@2x.png" />
                <GooglePlayWrapper>
                  <GooglePlayIcon
                    loading="lazy"
                    alt=""
                    src="/google-play.svg"
                  />
                </GooglePlayWrapper>
              </AppIcons1>
            </StoreIcons>
          </SocialBlock>
        </SocialMedia>
      </FooterContent>
      <Linkssection>
        <Col>
          <Titleblock>
            <Account>Account</Account>
          </Titleblock>
          <ListItems>
            <AllRightsReserved>Your account</AllRightsReserved>
            <AllRightsReserved>Your order</AllRightsReserved>
            <AllRightsReserved>Sell products on</AllRightsReserved>
            <AllRightsReserved>Browsing History</AllRightsReserved>
            <AllRightsReserved>Your Lists</AllRightsReserved>
          </ListItems>
        </Col>
        <Col1>
          <Titleblock>
            <Account>Company</Account>
          </Titleblock>
          <ListItems1>
            <Home>Home</Home>
            <AllRightsReserved>Buy</AllRightsReserved>
            <AllRightsReserved>Auction</AllRightsReserved>
            <AllRightsReserved>Wholesale Markets</AllRightsReserved>
            <AllRightsReserved>Imported Food Sourcing</AllRightsReserved>
            <AllRightsReserved>Traders</AllRightsReserved>
          </ListItems1>
        </Col1>
        <Col>
          <Titleblock>
            <Account>Support</Account>
          </Titleblock>
          <ListItems2>
            <AboutUs>About Us</AboutUs>
            <ContactUs>Contact</ContactUs>
            <AllRightsReserved>Help center</AllRightsReserved>
            <AboutUs>Terms of service</AboutUs>
            <AboutUs>Privacy policy</AboutUs>
          </ListItems2>
        </Col>
        <Col2 />
      </Linkssection>
    </FooterhomeRoot>
  );
};

FooterHome.propTypes = {
  className: PropTypes.string,
};

export default FooterHome;
