import styled from "styled-components";
import StartAuctionCard from "./StartAuctionCard";
import PropTypes from "prop-types";

const GetStartAuction = styled.div`
  width: 1216px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
`;
const Row = styled.div`
  width: 1216px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  max-width: 100%;
  font-size: var(--font-size-5xl);
  font-family: var(--font-ibm-plex-sans);
`;
const GetstartauctionsectionRoot = styled.section`
  align-self: stretch;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-50xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-11xl);
  background-image: url("/getstartauctionsection@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 100%;
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--neutral-white);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 800px) {
    padding-top: var(--padding-26xl);
    padding-bottom: var(--padding-26xl);
    box-sizing: border-box;
  }
`;

const GetStartAuctionSection = ({ className = "" }) => {
  return (
    <GetstartauctionsectionRoot className={className}>
      <GetStartAuction>Get Start Auction</GetStartAuction>
      <Row>
        <StartAuctionCard
          oSTOFFERASASELLER="OST OFFER AS A SELLER"
          asASellerPostOffersForThe="As a seller, post offers for the agricultural crop you are looking to sell, and galn Immedlate access to credit-verified buyers. Or simply react to an existing buyer's bid and start your transaction."
          nowButton="Register as a seller"
          nowButton1="Selling offer video"
        />
        <StartAuctionCard
          oSTOFFERASASELLER="OST BID AS A BUYER"
          asASellerPostOffersForThe="As a buyer, post bids for the agricultural crop you are looking to buy. Communicate to the market what you are looking for, and get rapid reactions from interested farmers or sellers."
          nowButton="Register as a buyer"
          nowButton1="Buying offer video"
        />
      </Row>
    </GetstartauctionsectionRoot>
  );
};

GetStartAuctionSection.propTypes = {
  className: PropTypes.string,
};

export default GetStartAuctionSection;
