import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  InputAdornment,
} from "@mui/material";
import MainHeader from "../components/MainHeader";
import HomePageHero from "../components/HomePageHero";
import BuyFromSourceIconBlock from "../components/BuyFromSourceIconBlock";
import FindingWholesalesMarketInRegio from "../components/FindingWholesalesMarketInRegio";
import ExploreCategorySection from "../components/ExploreCategorySection";
import SearchFoodCategorySection from "../components/SearchFoodCategorySection";
import BinhDienMarketSection from "../components/BinhDienMarketSection";
import FindSupplierLowestPriceSection from "../components/FindSupplierLowestPriceSection";
import AiAuctionNetwork from "../components/AiAuctionNetwork";
import GetStartAuctionSection from "../components/GetStartAuctionSection";
import styled from "styled-components";
import HomeAuctionCard from "../components/HomeAuctionCard";
import RecentlyViewed from "../components/RecentlyViewed";
import CustomerReviews from "../components/CustomerReviews";
import WebsiteProcess from "../components/WebsiteProcess";
import ContactSection from "../components/ContactSection";
import FooterHome from "../components/FooterHome";
import HomeAuctions from "../components/HomeAuctions";
import NowButton from "../components/NowButton";

const ScrollindicatorChild = styled.div`
  height: 12px;
  width: 12px;
  position: relative;
  border-radius: 50%;
  background-color: var(--neutral-white);
`;
const ScrollindicatorItem = styled.div`
  height: 12px;
  width: 12px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-gray-200);
`;
const Scrollindicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;

const FeaturedauctionsChild = styled.div`
  width: 907px;
  height: 2px;
  position: relative;
  background-color: var(--color-whitesmoke-200);
  display: none;
  max-width: 100%;
`;

const Recommendations = styled.div`
  align-self: stretch;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
`;
const AuctionscaroselWrapper = styled.div`
  align-self: stretch;
  overflow-x: auto;
  height: 472px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px var(--padding-smi);
  box-sizing: border-box;
  max-width: 100%;
`;
const ScrollindicatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 0px 607px;
  box-sizing: border-box;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    padding-left: 303px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const Recommendations1 = styled.section`
  align-self: stretch;
  background-color: var(--salad);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-61xl) 95px;
  box-sizing: border-box;
  gap: var(--gap-11xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--neutral-white);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 800px) {
    gap: var(--gap-mini);
    padding: var(--padding-33xl) var(--padding-28xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const Inputlabel1 = styled(InputLabel)``;
const TopOfPage = styled(MenuItem)``;
const Select1 = styled(Select)``;
const Formhelpertext1 = styled(FormHelperText)``;
const Nowbutton1 = styled(FormControl)`
  width: 166px;
  height: 34px;
  font-family: var(--font-poppins);
  font-weight: 500;
  font-size: var(--font-size-base);
  color: var(--neutral-white);
`;
const Topofpagesection = styled.section`
  align-self: stretch;
  height: 144px;
  background-color: var(--light-gray);
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media screen and (max-width: 450px) {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const HomepageRoot = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: normal;
  letter-spacing: normal;
`;

const Homepage = () => {
  return (
    <HomepageRoot>
      <MainHeader />
      <HomePageHero />
      <BuyFromSourceIconBlock />
      <FindingWholesalesMarketInRegio />
      <ExploreCategorySection />
      <SearchFoodCategorySection />
      <BinhDienMarketSection />
      <FindSupplierLowestPriceSection />
      <AiAuctionNetwork />
      <GetStartAuctionSection />
      <HomeAuctions />
      <RecentlyViewed />
      <HomeAuctions
        backgroundColor="var(--salad)"
        homeAuctionTitle="Recommendations"
      />
      <CustomerReviews />
      <WebsiteProcess />
      <ContactSection />
      <Topofpagesection>
        <NowButton
          nowButton="TOP OF PAGE"
          propMinWidth="145px"
          background="#7AC143"
          color="#fff"
        />
      </Topofpagesection>
      <FooterHome />
    </HomepageRoot>
  );
};

export default Homepage;
