import {
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Button,
} from "@mui/material";
import styled from "styled-components";
import PropTypes from "prop-types";

const PreRegisterToJoin = styled.div`
  width: 537px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
`;
const Titleblock = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  font-size: var(--regular-text-20-size);
`;
const SubmitBasicContact = styled.p`
  margin: 0;
`;
const SubmitBasicContactContainer = styled.div`
  width: 537px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
  min-height: 72px;
  max-width: 100%;
`;
const Blockinfo = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
`;
const ProofOfIdentity = styled.div`
  width: 537px;
  position: relative;
  line-height: 24px;
  display: inline-block;
  flex-shrink: 0;
  max-width: 100%;
`;
const Rowright = styled.div`
  flex: 0.9671;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-xl) var(--padding-230xl) 0px;
  box-sizing: border-box;
  gap: var(--gap-21xl);
  min-width: 395px;
  max-width: 100%;
  @media screen and (max-width: 1350px) {
    flex: 1;
  }
  @media screen and (max-width: 800px) {
    gap: var(--gap-xl);
    padding-bottom: var(--padding-143xl);
    box-sizing: border-box;
    min-width: 100%;
  }
`;
const Heading = styled.div`
  position: relative;
  letter-spacing: -0.02em;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
`;
const Titleblock1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Label = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 20px;
`;
const Input = styled(TextField)`
  border: none;
  background-color: transparent;
  align-self: stretch;
  height: 44px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-family: var(--font-ibm-plex-sans);
  font-size: var(--regular-14-size);
  color: var(--color-gray-200);
`;
const Inputfieldbase = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
`;
const HintText = styled.div`
  width: 250.3px;
  position: relative;
  line-height: 20px;
  font-family: var(--regular-text-20);
  color: var(--color-slategray);
  display: none;
`;
const InputField = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 187px;
`;
const Label1 = styled.div`
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 65px;
`;
const HintText1 = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 20px;
  font-family: var(--regular-text-20);
  color: var(--color-slategray);
  display: none;
`;
const Row = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    gap: var(--gap-base);
  }
`;
const Label2 = styled.div`
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 41px;
`;
const Input1 = styled(TextField)`
  border: none;
  background-color: transparent;
  align-self: stretch;
  height: 48px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-family: var(--font-ibm-plex-sans);
  font-size: var(--font-size-base);
  color: var(--color-gray-200);
`;
const HintText2 = styled.div`
  width: 320px;
  position: relative;
  font-size: var(--regular-14-size);
  line-height: 20px;
  font-family: var(--regular-text-20);
  color: var(--color-slategray);
  display: none;
`;
const InputField1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--font-size-base);
`;
const Label3 = styled.div`
  position: relative;
  line-height: 24px;
  display: inline-block;
  min-width: 106px;
`;
const Inputlabel1 = styled(InputLabel)``;
const Us = styled(MenuItem)``;
const Select1 = styled(Select)``;
const Formhelpertext1 = styled(FormHelperText)``;
const Dropdown = styled(FormControl)`
  height: 48px;
  width: 66px;
  font-family: var(--font-ibm-plex-sans);
  font-weight: 500;
  font-size: var(--regular-14-size);
  color: var(--color-gray-300);
`;
const MenuValue = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 24px;
`;
const MenuValueWrapper = styled.div`
  height: 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0px 0px;
  box-sizing: border-box;
  max-width: 100%;
`;
const HelpIcon = styled.img`
  height: 16px;
  width: 16px;
  position: relative;
  display: none;
`;
const TextInput = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-base) var(--padding-xs) 0px;
  box-sizing: border-box;
  gap: var(--gap-5xs);
  min-width: 352px;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    min-width: 100%;
  }
`;
const Input2 = styled.div`
  align-self: stretch;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: var(--br-5xs);
  background-color: var(--light-gray);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  color: var(--color-gray-200);
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`;
const InputFieldBase = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xs);
  max-width: 100%;
`;
const InputField2 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  font-size: var(--font-size-base);
`;
const TextareaInputField = styled(TextField)`
  border: none;
  background-color: transparent;
  align-self: stretch;
  height: 74px;
  font-family: var(--font-ibm-plex-sans);
  font-size: var(--regular-14-size);
  color: var(--medium-gray);
`;
const Checkbox = styled.input`
  margin: 0;
  height: 20px;
  width: 20px;
`;
const Text1 = styled.div`
  flex: 1;
  position: relative;
  line-height: 20px;
  display: inline-block;
  min-width: 195px;
  max-width: 100%;
`;
const Checkbox1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
  max-width: 100%;
  color: var(--color-gray-200);
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`;
const FormFields = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  max-width: 100%;
`;
const Actions = styled(Button)`
  align-self: stretch;
  height: 54px;
`;
const Form = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--regular-14-size);
  color: var(--medium-gray);
  font-family: var(--font-ibm-plex-sans);
  @media screen and (max-width: 800px) {
    gap: var(--gap-base);
  }
`;
const Rowleft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-11xl);
  min-width: 395px;
  max-width: 100%;
  text-align: center;
  font-size: var(--regular-text-20-size);
  @media screen and (max-width: 1350px) {
    flex: 1;
  }
  @media screen and (max-width: 800px) {
    min-width: 100%;
  }
`;
const Row1 = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  row-gap: 20px;
  @media screen and (max-width: 1350px) {
    flex-wrap: wrap;
  }
`;
const ContactsectionRoot = styled.section`
  align-self: stretch;
  background-color: var(--neutral-white);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-93xl);
  box-sizing: border-box;
  max-width: 100%;
  z-index: 5;
  text-align: left;
  font-size: var(--font-size-mid);
  color: var(--color-gray-200);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 800px) {
    padding: var(--padding-33xl) var(--padding-37xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;

const ContactSection = ({ className = "" }) => {
  return (
    <ContactsectionRoot className={className}>
      <Row1>
        <Rowright>
          <Titleblock>
            <PreRegisterToJoin>
              Pre-Register to Join our Auction Network Today!
            </PreRegisterToJoin>
          </Titleblock>
          <Blockinfo>
            <SubmitBasicContactContainer>
              <SubmitBasicContact>{`Submit basic contact information to our team and we will get in contact with you to finish your application. `}</SubmitBasicContact>
            </SubmitBasicContactContainer>
          </Blockinfo>
          <Blockinfo>
            <ProofOfIdentity>
              Proof of identity and liquidity will be required. This process can
              take up to 5 business days to complete!
            </ProofOfIdentity>
          </Blockinfo>
        </Rowright>
        <Rowleft>
          <Titleblock1>
            <Heading>Contact Information</Heading>
          </Titleblock1>
          <Form>
            <FormFields>
              <Row>
                <InputField>
                  <Inputfieldbase>
                    <Inputfieldbase>
                      <Label>First name</Label>
                      <Input
                        placeholder="First name"
                        variant="outlined"
                        sx={{
                          "& fieldset": { border: "none" },
                          "& .MuiInputBase-root": {
                            height: "44px",
                            backgroundColor: "#f6f6f6",
                            borderRadius: "8px",
                            fontSize: "14px",
                          },
                          "& .MuiInputBase-input": { color: "#222" },
                        }}
                      />
                    </Inputfieldbase>
                    <HintText>This is a hint text to help user.</HintText>
                  </Inputfieldbase>
                </InputField>
                <InputField>
                  <Inputfieldbase>
                    <Inputfieldbase>
                      <Label1>Last name</Label1>
                      <Input
                        placeholder="Last name"
                        variant="outlined"
                        sx={{
                          "& fieldset": { border: "none" },
                          "& .MuiInputBase-root": {
                            height: "44px",
                            backgroundColor: "#f6f6f6",
                            borderRadius: "8px",
                            fontSize: "14px",
                          },
                          "& .MuiInputBase-input": { color: "#222" },
                        }}
                      />
                    </Inputfieldbase>
                    <HintText1>This is a hint text to help user.</HintText1>
                  </Inputfieldbase>
                </InputField>
              </Row>
              <InputField1>
                <Inputfieldbase>
                  <Inputfieldbase>
                    <Label2>Email</Label2>
                    <Input1
                      placeholder="you@company.com"
                      variant="outlined"
                      sx={{
                        "& fieldset": { border: "none" },
                        "& .MuiInputBase-root": {
                          height: "48px",
                          backgroundColor: "#f6f6f6",
                          borderRadius: "8px",
                        },
                        "& .MuiInputBase-input": { color: "#222" },
                      }}
                    />
                  </Inputfieldbase>
                  <HintText2>This is a hint text to help user.</HintText2>
                </Inputfieldbase>
              </InputField1>
              <InputField2>
                <InputFieldBase>
                  <InputFieldBase>
                    <Label3>Phone number</Label3>
                    <Input2>
                      <Dropdown
                        variant="standard"
                        sx={{
                          borderTopWidth: "1px",
                          borderRightWidth: "1px",
                          borderBottomWidth: "1px",
                          borderLeftWidth: "1px",
                          borderRadius: "0px 0px 0px 0px",
                          width: "66px",
                          height: "48px",
                          m: 0,
                          p: 0,
                          "& .MuiInputBase-root": {
                            m: 0,
                            p: 0,
                            minHeight: "48px",
                            justifyContent: "center",
                            display: "inline-flex",
                          },
                          "& .MuiInputLabel-root": {
                            m: 0,
                            p: 0,
                            minHeight: "48px",
                            display: "inline-flex",
                          },
                          "& .MuiMenuItem-root": {
                            m: 0,
                            p: 0,
                            height: "48px",
                            display: "inline-flex",
                          },
                          "& .MuiSelect-select": {
                            m: 0,
                            p: 0,
                            height: "48px",
                            alignItems: "center",
                            display: "inline-flex",
                          },
                          "& .MuiInput-input": { m: 0, p: 0 },
                          "& .MuiInputBase-input": {
                            color: "#101828",
                            fontSize: 14,
                            fontWeight: "Medium",
                            fontFamily: "IBM Plex Sans",
                            textAlign: "left",
                            p: "0 !important",
                            marginLeft: "16px",
                          },
                        }}
                      >
                        <Inputlabel1 color="primary" />
                        <Select1
                          color="primary"
                          disableUnderline
                          displayEmpty
                          IconComponent={() => (
                            <img
                              width="20px"
                              height="20px"
                              src="/chevrondown.svg"
                              style={{ marginRight: "12px" }}
                            />
                          )}
                        >
                          <Us>US</Us>
                        </Select1>
                        <Formhelpertext1 />
                      </Dropdown>
                      <TextInput>
                        <MenuValueWrapper>
                          <MenuValue>+1 (555) 000-0000</MenuValue>
                        </MenuValueWrapper>
                        <HelpIcon
                          loading="lazy"
                          alt=""
                          src="/arrow--arrow-left-lg-4.svg"
                        />
                      </TextInput>
                    </Input2>
                  </InputFieldBase>
                  <HintText2>This is a hint text to help user.</HintText2>
                </InputFieldBase>
              </InputField2>
              <TextareaInputField
                placeholder="Message"
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                  "& .MuiInputBase-root": {
                    height: "74px",
                    borderRadius: "0px 0px 0px 0px",
                    fontSize: "14px",
                  },
                  "& .MuiInputBase-input": { color: "#646565" },
                }}
              />
              <Checkbox1>
                <Checkbox type="checkbox" />
                <Text1>You agree to our friendly privacy policy.</Text1>
              </Checkbox1>
            </FormFields>
            <Actions
              disableElevation
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "20",
                background: "#fb811f",
                borderRadius: "0px 0px 0px 0px",
                "&:hover": { background: "#fb811f" },
                height: 54,
              }}
            >
              Send message
            </Actions>
          </Form>
        </Rowleft>
      </Row1>
    </ContactsectionRoot>
  );
};

ContactSection.propTypes = {
  className: PropTypes.string,
};

export default ContactSection;
