import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

const SnUGi = styled.h1`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 58px;
  font-weight: 700;
  font-family: inherit;
  @media screen and (max-width: 800px) {
    font-size: var(--font-size-23xl);
    line-height: 46px;
  }
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-12xl);
    line-height: 35px;
  }
`;
const ConnectsLocalFamers = styled.h2`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-9xl);
  letter-spacing: 0.08em;
  line-height: 27px;
  font-weight: 400;
  font-family: var(--regular-text-20);
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-3xl);
    line-height: 22px;
  }
`;
const SnUGiNngSnAibbeeParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-6xs);
`;
const Searchbar = styled(TextField)`
  border: none;
  background-color: transparent;
  width: 612px;
  height: 54px;
  font-family: var(--regular-text-20);
  font-size: var(--regular-text-15-size);
  color: var(--color-gray-100);
  max-width: 100%;
`;
const Heromiddleblock = styled.div`
  flex: 1;
  border-radius: var(--br-xl);
  background-color: var(--color-gray-500);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-51xl-5) var(--padding-19xl);
  box-sizing: border-box;
  gap: var(--gap-13xl);
  max-width: 100%;
  @media screen and (max-width: 450px) {
    gap: var(--gap-base);
  }
`;
const HomepageheroRoot = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-49xl) var(--padding-183xl);
  box-sizing: border-box;
  background-image: url("/homepagehero@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-33xl);
  color: var(--neutral-white);
  font-family: var(--font-helvetica);
  @media screen and (max-width: 1350px) {
    padding-left: var(--padding-82xl);
    padding-right: var(--padding-82xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 800px) {
    padding-left: var(--padding-31xl);
    padding-right: var(--padding-31xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding: var(--padding-25xl) var(--padding-xl);
    box-sizing: border-box;
  }
`;

const SearchBarButton = styled(Button)`
  height: 100%;
  width: 87px;
  background: #fb811f;
  color: white;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
`;

const HomePageHero = ({ className = "" }) => {
  return (
    <HomepageheroRoot className={className}>
      <Heromiddleblock>
        <SnUGiNngSnAibbeeParent>
          <SnUGi>Sàn Đấu Giá Nông Sản AiBbee</SnUGi>
          <ConnectsLocalFamers>
            Connects Local Famers with Wholesale Suppliers
          </ConnectsLocalFamers>
        </SnUGiNngSnAibbeeParent>
        <Searchbar
          placeholder="Search Auction Bidding "
          variant="outlined"
          InputProps={{
            endAdornment: (
              // <img width="24px" height="24px" src="/mingcute_search-line.svg" />
              <SearchBarButton>
                <img
                  width="24px"
                  height="24px"
                  src="/mingcute_search-line.svg"
                />
              </SearchBarButton>
            ),
          }}
          sx={{
            "& fieldset": { border: "none" },
            "& .MuiInputBase-root": {
              height: "54px",
              backgroundColor: "#f3f3f3",
              paddingRight: "0px",
              borderRadius: "40px",
              fontSize: "14px",
            },
            "& .MuiInputBase-input": { color: "#797979" },
            width: "612px",
          }}
        />
      </Heromiddleblock>
    </HomepageheroRoot>
  );
};

HomePageHero.propTypes = {
  className: PropTypes.string,
};

export default HomePageHero;
