import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  InputAdornment,
} from "@mui/material";
import styled from "styled-components";
import PropTypes from "prop-types";

const GroupIcon = styled.img`
  height: 10.1px;
  width: 9.6px;
  position: relative;
  z-index: 1;
`;
const GroupIcon1 = styled.img`
  height: 9.9px;
  width: 3.1px;
  position: relative;
  z-index: 1;
`;
const GroupIcon2 = styled.img`
  height: 10px;
  width: 7.4px;
  position: relative;
  z-index: 1;
`;
const GroupIcon3 = styled.img`
  height: 7.3px;
  width: 6.8px;
  position: relative;
`;
const GroupIcon4 = styled.img`
  height: 7px;
  width: 11.9px;
  position: relative;
`;
const GroupIcon5 = styled.img`
  height: 7.3px;
  width: 6.2px;
  position: relative;
`;
const GroupIcon6 = styled.img`
  height: 7.1px;
  width: 5.6px;
  position: relative;
`;
const GroupIcon7 = styled.img`
  width: 7.4px;
  height: 10.6px;
  position: relative;
`;
const ImageFour = styled.div`
  width: 10.8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-10xs) 0px 0px;
  box-sizing: border-box;
`;
const GroupIcon8 = styled.img`
  height: 10.1px;
  width: 9.6px;
  position: relative;
`;
const GroupIcon9 = styled.img`
  height: 7.1px;
  width: 7.6px;
  position: relative;
`;
const GroupParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: var(--gap-12xs-7);
`;
const GroupIcon10 = styled.img`
  height: 7.3px;
  width: 5.7px;
  position: relative;
`;
const GroupIcon11 = styled.img`
  height: 8.9px;
  width: 5.1px;
  position: relative;
`;
const GroupIcon12 = styled.img`
  height: 9.9px;
  width: 3.1px;
  position: relative;
`;
const GroupIcon13 = styled.img`
  width: 7.6px;
  height: 7.1px;
  position: relative;
`;
const ImageFive = styled.div`
  width: 11.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-9xs) 0px 0px;
  box-sizing: border-box;
`;
const GroupIcon14 = styled.img`
  height: 10px;
  width: 7.4px;
  position: relative;
`;
const GroupIcon15 = styled.img`
  align-self: stretch;
  width: 3.2px;
  position: relative;
  max-height: 100%;
  min-height: 11px;
`;
const GroupIcon16 = styled.img`
  height: 7.3px;
  width: 6.6px;
  position: relative;
`;
const GroupIcon17 = styled.img`
  width: 5.1px;
  height: 8.9px;
  position: relative;
`;
const ImageSix = styled.div`
  width: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-9xs) 0px 0px;
  box-sizing: border-box;
`;
const GroupIcon18 = styled.img`
  height: 7.3px;
  width: 6.8px;
  position: relative;
  z-index: 1;
`;
const GroupIcon19 = styled.img`
  height: 7.1px;
  width: 11.9px;
  position: relative;
`;
const ImageFiveParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 0px var(--padding-11xs) 0px 0px;
  gap: var(--gap-12xs-7);
`;
const GroupIcon20 = styled.img`
  height: 10.5px;
  width: 5.1px;
  position: absolute;
  margin: 0 !important;
  right: 13.4px;
  bottom: 15.6px;
`;
const ClipPathGroup = styled.img`
  position: absolute;
  height: 100%;
  top: 0px;
  bottom: 0px;
  left: 0px;
  max-height: 100%;
  width: 77.7px;
  object-fit: cover;
`;
const VectorIcon = styled.img`
  position: absolute;
  top: 19px;
  left: 76.5px;
  width: 12.4px;
  height: 14px;
  z-index: 1;
`;
const LogoShapeIcon = styled.img`
  position: absolute;
  top: 19.3px;
  left: 94.2px;
  width: 11.4px;
  height: 13.6px;
`;
const VectorIcon1 = styled.img`
  position: absolute;
  top: 19px;
  left: 110.8px;
  width: 16.1px;
  height: 17.5px;
`;
const LogoShapeIcon1 = styled.img`
  position: absolute;
  top: 19.3px;
  left: 137.9px;
  width: 13.4px;
  height: 13.6px;
`;
const GroupIcon21 = styled.img`
  position: absolute;
  top: 15.8px;
  left: 155.1px;
  width: 14.3px;
  height: 17.1px;
`;
const GroupIcon22 = styled.img`
  position: absolute;
  top: 19.3px;
  left: 173.7px;
  width: 11.4px;
  height: 13.8px;
`;
const GroupIcon23 = styled.img`
  position: absolute;
  top: 19.3px;
  left: 199.2px;
  width: 13.8px;
  height: 13.6px;
`;
const GroupIcon24 = styled.img`
  position: absolute;
  top: 15.8px;
  left: 218px;
  width: 13.8px;
  height: 17.3px;
`;
const GroupIcon25 = styled.img`
  position: absolute;
  top: 19.3px;
  left: 236.7px;
  width: 2.9px;
  height: 13.6px;
`;
const VectorIcon2 = styled.img`
  position: absolute;
  top: 38.6px;
  left: 77.5px;
  width: 163.4px;
  height: 2px;
  z-index: 1;
`;
const ImageEight = styled.div`
  height: 71.7px;
  flex: 1;
  position: relative;
`;
const ImageSeven = styled.div`
  width: 240.9px;
  margin: 0 !important;
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Navlogo = styled.div`
  width: 254px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-26xl-6) 0px var(--padding-base-4) var(--padding-43xl);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-11xs-1);
  @media screen and (max-width: 450px) {
    padding-left: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const Home = styled.a`
  text-decoration: none;
  position: relative;
  line-height: 22px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  cursor: pointer;
  min-width: 51px;
`;
const Buy = styled.a`
  text-decoration: none;
  position: relative;
  line-height: 22px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  cursor: pointer;
  min-width: 34px;
`;
const Auction = styled.a`
  text-decoration: none;
  position: relative;
  line-height: 22px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  cursor: pointer;
  min-width: 67px;
`;
const ChUMi = styled.a`
  text-decoration: none;
  position: relative;
  line-height: 22px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  cursor: pointer;
  min-width: 111px;
  white-space: nowrap;
`;
const ThngLi = styled.a`
  text-decoration: none;
  position: relative;
  line-height: 22px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  cursor: pointer;
  min-width: 97px;
  white-space: nowrap;
`;
const Ocop = styled.a`
  text-decoration: none;
  position: relative;
  line-height: 22px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  cursor: pointer;
  min-width: 53px;
`;
const Contact = styled.a`
  text-decoration: none;
  position: relative;
  line-height: 22px;
  font-weight: 500;
  color: inherit;
  display: inline-block;
  cursor: pointer;
  min-width: 69px;
`;
const Navlinksblock = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px var(--padding-8xs) 0px var(--padding-9xs);
  gap: var(--gap-xl);
  @media screen and (max-width: 1350px) {
    display: none;
  }
`;
const NavigationLinks = styled.div`
  width: 669px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) 0px 0px;
  box-sizing: border-box;
  max-width: 100%;
  @media screen and (max-width: 1350px) {
    width: 0px;
  }
`;
const FlagsIcon = styled.img`
  height: 16px;
  width: 24px;
  position: relative;
  border-radius: var(--br-12xs);
  overflow: hidden;
  flex-shrink: 0;
`;
const Inputlabel1 = styled(InputLabel)``;
const En = styled(MenuItem)``;
const Select1 = styled(Select)``;
const Formhelpertext1 = styled(FormHelperText)``;
const LanguageSelector = styled(FormControl)`
  height: 22px;
  flex: 1;
  font-family: var(--regular-text-20);
  font-weight: 600;
  font-size: var(--font-size-lg);
  color: var(--neutral-white);
`;
const DropdownContainer = styled.div`
  width: 58px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
`;
const Languages = styled.div`
  width: 81.6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px 0px;
  box-sizing: border-box;
  gap: var(--gap-3xs);
  flex-shrink: 0;
`;
const SellWithUs = styled.a`
  text-decoration: none;
  position: relative;
  line-height: 22px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 100px;
  white-space: nowrap;
`;
const Sellwithusbutton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-3xs) 0px var(--padding-3xs) var(--padding-3xs);
`;
const SingUp = styled.a`
  text-decoration: none;
  position: relative;
  font-size: var(--font-size-base);
  line-height: 18px;
  font-weight: 600;
  font-family: var(--regular-text-20);
  color: var(--neutral-white);
  text-align: left;
  display: inline-block;
  min-width: 66px;
`;
const SignUp = styled.button`
  cursor: pointer;
  border: none;
  padding: var(--padding-3xs) var(--padding-5xs) var(--padding-3xs)
    var(--padding-xl);
  background-color: var(--color-darkorange);
  border-radius: var(--br-xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  &:hover {
    background-color: var(--color-chocolate);
  }
`;
const LogIn = styled.a`
  text-decoration: none;
  position: relative;
  line-height: 22px;
  font-weight: 600;
  color: inherit;
  display: inline-block;
  min-width: 53px;
  white-space: nowrap;
`;
const Login = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const VectorIcon3 = styled.img`
  width: 18.6px;
  height: 0px;
  position: relative;
`;
const Hamburgericon = styled.div`
  border-radius: var(--br-10xs);
  background-color: var(--neutral-white);
  overflow: hidden;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xs) var(--padding-4xs);
  gap: var(--gap-4xs);
`;
const Navrightbuttons = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0px var(--padding-3xs) 0px 0px;
  box-sizing: border-box;
  gap: var(--gap-3xs);
  cursor: pointer;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const LinksContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xl);
  max-width: 100%;
`;
const Navigation = styled.div`
  width: 1046.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-mini-8) 0px 0px;
  box-sizing: border-box;
  max-width: 100%;
`;
const MainheaderRoot = styled.header`
  align-self: stretch;
  background-color: var(--blue);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-6xs) var(--padding-5xs);
  box-sizing: border-box;
  top: 0;
  z-index: 99;
  position: sticky;
  gap: var(--gap-xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--neutral-white);
  font-family: var(--regular-text-20);
`;

const MainHeader = ({ className = "" }) => {
  return (
    <MainheaderRoot className={className}>
      <Navlogo>
        <GroupIcon alt="" src="/group.svg" />
        <GroupIcon1 alt="" src="/group-1.svg" />
        <GroupIcon2 alt="" src="/group-2.svg" />
        <GroupIcon3 alt="" src="/group-3.svg" />
        <GroupParent>
          <GroupIcon4 alt="" src="/group-4.svg" />
          <GroupIcon5 alt="" src="/group-5.svg" />
          <GroupIcon6 alt="" src="/group-6.svg" />
          <GroupIcon5 alt="" src="/group-7.svg" />
          <ImageFour>
            <GroupIcon7 alt="" src="/group-8.svg" />
          </ImageFour>
          <GroupIcon8 alt="" src="/group-9.svg" />
          <GroupIcon9 alt="" src="/group-10.svg" />
        </GroupParent>
        <GroupIcon10 alt="" src="/group-11.svg" />
        <GroupIcon11 alt="" src="/group-12.svg" />
        <GroupIcon12 alt="" src="/group-13.svg" />
        <GroupIcon3 alt="" src="/group-14.svg" />
        <ImageFiveParent>
          <ImageFive>
            <GroupIcon13 alt="" src="/group-15.svg" />
          </ImageFive>
          <GroupIcon14 alt="" src="/group-16.svg" />
          <GroupIcon15 alt="" src="/group-17.svg" />
          <GroupIcon16 alt="" src="/group-18.svg" />
          <ImageSix>
            <GroupIcon17 alt="" src="/group-19.svg" />
          </ImageSix>
          <GroupIcon18 alt="" src="/group-20.svg" />
          <GroupIcon6 alt="" src="/group-21.svg" />
          <GroupIcon19 alt="" src="/group-22.svg" />
        </ImageFiveParent>
        <ImageSeven>
          <GroupIcon20 alt="" src="/group-23.svg" />
          <ImageEight>
            <ClipPathGroup alt="" src="/clip-path-group@2x.png" />
            <VectorIcon alt="" src="/vector.svg" />
            <LogoShapeIcon alt="" src="/vector-1.svg" />
            <VectorIcon1 alt="" src="/vector-2.svg" />
            <LogoShapeIcon1 alt="" src="/vector-3.svg" />
            <GroupIcon21 alt="" src="/group-24.svg" />
            <GroupIcon22 alt="" src="/group-25.svg" />
            <GroupIcon23 alt="" src="/group-26.svg" />
            <GroupIcon24 alt="" src="/group-27.svg" />
            <GroupIcon25 alt="" src="/group-28.svg" />
            <VectorIcon2 alt="" src="/vector-4.svg" />
          </ImageEight>
        </ImageSeven>
      </Navlogo>
      <Navigation>
        <LinksContainer>
          <NavigationLinks>
            <Navlinksblock>
              <Home>Home</Home>
              <Buy>Buy</Buy>
              <Auction>Auction</Auction>
              <ChUMi>Chợ Đầu Mối</ChUMi>
              <ThngLi>Thương Lái</ThngLi>
              <Ocop>OCOP</Ocop>
              <Contact>Contact</Contact>
            </Navlinksblock>
          </NavigationLinks>
          <Navrightbuttons>
            <Languages>
              <FlagsIcon alt="" src="/flags.svg" />
              <DropdownContainer>
                <LanguageSelector
                  variant="standard"
                  sx={{
                    borderTopWidth: "1px",
                    borderRightWidth: "1px",
                    borderBottomWidth: "1px",
                    borderLeftWidth: "1px",
                    borderRadius: "0px 0px 0px 0px",
                    width: "58px",
                    height: "22px",
                    m: 0,
                    p: 0,
                    "& .MuiInputBase-root": {
                      m: 0,
                      p: 0,
                      minHeight: "22px",
                      justifyContent: "center",
                      display: "inline-flex",
                    },
                    "& .MuiInputLabel-root": {
                      m: 0,
                      p: 0,
                      minHeight: "22px",
                      display: "inline-flex",
                    },
                    "& .MuiMenuItem-root": {
                      m: 0,
                      p: 0,
                      height: "22px",
                      display: "inline-flex",
                    },
                    "& .MuiSelect-select": {
                      m: 0,
                      p: 0,
                      height: "22px",
                      alignItems: "center",
                      display: "inline-flex",
                    },
                    "& .MuiInput-input": { m: 0, p: 0 },
                    "& .MuiInputBase-input": {
                      color: "#fff",
                      fontSize: 18,
                      fontWeight: "Semi Bold",
                      fontFamily: "Inter",
                      textAlign: "left",
                      p: "0 !important",
                    },
                  }}
                >
                  <Inputlabel1 color="primary" />
                  <Select1
                    color="primary"
                    disableUnderline
                    displayEmpty
                    IconComponent={() => (
                      <img
                        width="20px"
                        height="20px"
                        src="/iconsolidcheverondown.svg"
                        style={{}}
                      />
                    )}
                  >
                    <En>En</En>
                  </Select1>
                  <Formhelpertext1 />
                </LanguageSelector>
              </DropdownContainer>
            </Languages>
            <Sellwithusbutton>
              <SellWithUs>Sell with us</SellWithUs>
            </Sellwithusbutton>
            <SignUp>
              <SingUp>Sing up</SingUp>
            </SignUp>
            <Login>
              <LogIn>Log in</LogIn>
            </Login>
            <Hamburgericon>
              <VectorIcon3 alt="" src="/vector-5.svg" />
              <VectorIcon3 alt="" src="/vector-5.svg" />
              <VectorIcon3 alt="" src="/vector-5.svg" />
            </Hamburgericon>
          </Navrightbuttons>
        </LinksContainer>
      </Navigation>
    </MainheaderRoot>
  );
};

MainHeader.propTypes = {
  className: PropTypes.string,
};

export default MainHeader;
