import styled from "styled-components";
import PropTypes from "prop-types";
import GoMarketButton from "./GoMarketButton";

const FindSupplierFor = styled.div`
  position: relative;
  letter-spacing: -0.02em;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--regular-text-20);
  display: flex;
  align-items: start;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
`;
const SourcingOcopBroker = styled.div`
  width: 212px;
  position: relative;
  line-height: 150%;
  text-transform: capitalize;
  font-weight: 600;
  white-space: pre-wrap;
  display: none;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
`;
const SourcingFarmer = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: capitalize;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
`;
const Farmer = styled.div`
  position: relative;
  line-height: 27px;
  text-transform: capitalize;
  font-weight: 600;
  display: inline-block;
  min-width: 63px;
`;
const BinhDienMarket = styled.div`
  align-self: stretch;
  position: relative;
  font-size: var(--regular-14-size);
  line-height: 18px;
  text-transform: capitalize;
`;
const Infocardcontentfooter = styled.div`
  flex: 1;
  border-radius: var(--br-mini);
  background-color: var(--color-gray-700);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  gap: var(--gap-xs);
`;
const Infocardcontentmain = styled.div`
  height: 385px;
  flex: 1;
  border-radius: var(--br-xl);
  background: linear-gradient(
    180deg,
    rgba(49, 49, 49, 0.53),
    rgba(111, 120, 107, 0)
  );
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-3xs);
  box-sizing: border-box;
`;
const Infocard = styled.div`
  flex: 1;
  border-radius: var(--br-mini);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url("/infocard1@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-width: 188px;
`;
const TheWestCentral = styled.li``;
const TheWestCentralPacific = styled.ul`
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-xl);
`;
const TheWestCentralContainer = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 24px;
  text-transform: capitalize;
`;
const TheWestCentralPacificParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-8xs);
`;
const Cardfooterbutton = styled.div`
  position: relative;
  font-size: var(--semibold-12-size);
  line-height: 15px;
  font-weight: 600;
  color: var(--salad);
  display: inline-block;
  min-width: 41px;
`;
const Supplierzonecontent = styled.div`
  flex: 1;
  border-radius: var(--br-4xl);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: var(--padding-3xs) var(--padding-xl) var(--padding-4xs);
  height: 100%;
  ${
    "" /* gap: var(--gap-192xl);
  @media screen and (max-width: 450px) {
    gap: var(--gap-86xl);
  } */
  }
`;
const Supplierzonelistcard = styled.div`
  flex: 1;
  border-radius: var(--br-xl);
  background-color: var(--neutral-white);
  border: 1px solid var(--gray-light2);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 188px;
  height: 100%;
  @media screen and (max-width: 659px) {
    height: fit-content;
  }
`;
const FarmerCards = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`;
const GoToThe = styled.div`
  position: relative;
  line-height: 22px;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 18px;
  }
`;
const GoToTheMarketPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-12xs) 0px 0px;
`;
const ArrowArrowLeftLg = styled.img`
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  min-height: 24px;
`;
const Gomarketbutton1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  width: 100%;
  font-size: var(--regular-text-20-size);
  color: var(--salad);
`;
const FarmerList = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-31xl);
  max-width: 100%;
  font-size: var(--regular-text-15-size);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 800px) {
    gap: var(--gap-6xl);
  }
`;
const FarmerSourcing = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
  ${"" /* min-width: 389px; */}
  max-width: 100%;
  @media screen and (max-width: 800px) {
    min-width: 100%;
  }
`;
const DailyCatches = styled.div`
  position: relative;
  line-height: 27px;
  text-transform: capitalize;
  font-weight: 600;
  display: inline-block;
  min-width: 101px;
`;
const Infocardcontentmain1 = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-xl);
  background: linear-gradient(
    180deg,
    rgba(49, 49, 49, 0.53),
    rgba(111, 120, 107, 0)
  );
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding: var(--padding-3xs);
`;
const Infocard1 = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-mini);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-image: url("/infocard2@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  font-size: var(--regular-text-15-size);
  font-family: var(--regular-text-20);
`;
const SupplierTypes = styled.div`
  height: 445px;
  width: 289px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-11xl);
`;
const Supplierzonelistcard1 = styled.div`
  align-self: stretch;
  border-radius: var(--br-xl);
  background-color: var(--neutral-white);
  border: 1px solid var(--gray-light2);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const SupplierTypes1 = styled.div`
  width: 289px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-41xl) 0px 0px;
  box-sizing: border-box;
  font-size: var(--regular-text-15-size);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 450px) {
    padding-top: var(--padding-20xl);
    box-sizing: border-box;
  }
`;
const SupplierCards = styled.div`
  ${"" /* width: 1216px; */}
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${"" /* align-items: flex-start; */}
  justify-content: flex-start;
  gap: var(--gap-xl);
  max-width: 100%;
  padding: var(--padding-51xl) var(--padding-93xl);
  @media screen and (max-width: 800px) {
    padding: var(--padding-20xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding: 0px;
    box-sizing: border-box;
  }
`;
const FindsupplierlowestpricesectionChild = styled.div`
  width: 907px;
  height: 2px;
  position: relative;
  background-color: var(--color-whitesmoke-200);
  display: none;
  max-width: 100%;
`;
const FindsupplierlowestpricesectionRoot = styled.section`
  align-self: stretch;
  background-color: var(--light-gray);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-51xl) var(--padding-51xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--color-gray-200);
  font-family: var(--font-ibm-plex-sans);
  @media screen and (max-width: 800px) {
    padding: var(--padding-26xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding: 20px;
    box-sizing: border-box;
  }
`;

const FindSupplierLowestPriceSection = ({ className = "" }) => {
  return (
    <FindsupplierlowestpricesectionRoot className={className}>
      <FindSupplierFor>Find Supplier for Lowest $ Sourcing</FindSupplierFor>
      <SourcingOcopBroker>Sourcing OCOP Broker</SourcingOcopBroker>
      <SupplierCards>
        <FarmerSourcing>
          <SourcingFarmer>Sourcing Farmer</SourcingFarmer>
          <FarmerList>
            <FarmerCards>
              <Infocard>
                <Infocardcontentmain>
                  <Infocardcontentfooter>
                    <Farmer>Farmer 1</Farmer>
                    <BinhDienMarket>
                      Binh Dien Market is one of the largest agricultural food
                      markets in Ho Chi Minh City. This is also the only
                      wholesale market supplying seafood to the market here.
                    </BinhDienMarket>
                  </Infocardcontentfooter>
                </Infocardcontentmain>
              </Infocard>
              <Supplierzonelistcard>
                <Supplierzonecontent>
                  <TheWestCentralPacificParent>
                    <TheWestCentralContainer>
                      <TheWestCentralPacific>
                        <TheWestCentral>
                          The West Central Pacific
                        </TheWestCentral>
                      </TheWestCentralPacific>
                    </TheWestCentralContainer>
                    <TheWestCentralContainer>
                      <TheWestCentralPacific>
                        <TheWestCentral>
                          The West Central Pacific
                        </TheWestCentral>
                      </TheWestCentralPacific>
                    </TheWestCentralContainer>
                    <TheWestCentralPacificParent>
                      <TheWestCentralContainer>
                        <TheWestCentralPacific>
                          <TheWestCentral>
                            The West Central Pacific
                          </TheWestCentral>
                        </TheWestCentralPacific>
                      </TheWestCentralContainer>
                      <TheWestCentralContainer>
                        <TheWestCentralPacific>
                          <TheWestCentral>
                            The West Central Pacific
                          </TheWestCentral>
                        </TheWestCentralPacific>
                      </TheWestCentralContainer>
                      <TheWestCentralContainer>
                        <TheWestCentralPacific>
                          <TheWestCentral>
                            The West Central Pacific
                          </TheWestCentral>
                        </TheWestCentralPacific>
                      </TheWestCentralContainer>
                    </TheWestCentralPacificParent>
                  </TheWestCentralPacificParent>
                  <Cardfooterbutton>See All</Cardfooterbutton>
                </Supplierzonecontent>
              </Supplierzonelistcard>
            </FarmerCards>
          </FarmerList>
        </FarmerSourcing>
        <FarmerSourcing>
          <SourcingFarmer>OCOP Supplier</SourcingFarmer>
          <FarmerList>
            <FarmerCards>
              <Infocard>
                <Infocardcontentmain>
                  <Infocardcontentfooter>
                    <Farmer>Farmer 1</Farmer>
                    <BinhDienMarket>
                      Binh Dien Market is one of the largest agricultural food
                      markets in Ho Chi Minh City. This is also the only
                      wholesale market supplying seafood to the market here.
                    </BinhDienMarket>
                  </Infocardcontentfooter>
                </Infocardcontentmain>
              </Infocard>
              <Supplierzonelistcard>
                <Supplierzonecontent>
                  <TheWestCentralPacificParent>
                    <TheWestCentralContainer>
                      <TheWestCentralPacific>
                        <TheWestCentral>
                          The West Central Pacific
                        </TheWestCentral>
                      </TheWestCentralPacific>
                    </TheWestCentralContainer>
                    <TheWestCentralContainer>
                      <TheWestCentralPacific>
                        <TheWestCentral>
                          The West Central Pacific
                        </TheWestCentral>
                      </TheWestCentralPacific>
                    </TheWestCentralContainer>
                    <TheWestCentralPacificParent>
                      <TheWestCentralContainer>
                        <TheWestCentralPacific>
                          <TheWestCentral>
                            The West Central Pacific
                          </TheWestCentral>
                        </TheWestCentralPacific>
                      </TheWestCentralContainer>
                      <TheWestCentralContainer>
                        <TheWestCentralPacific>
                          <TheWestCentral>
                            The West Central Pacific
                          </TheWestCentral>
                        </TheWestCentralPacific>
                      </TheWestCentralContainer>
                      <TheWestCentralContainer>
                        <TheWestCentralPacific>
                          <TheWestCentral>
                            The West Central Pacific
                          </TheWestCentral>
                        </TheWestCentralPacific>
                      </TheWestCentralContainer>
                    </TheWestCentralPacificParent>
                  </TheWestCentralPacificParent>
                  <Cardfooterbutton>See All</Cardfooterbutton>
                </Supplierzonecontent>
              </Supplierzonelistcard>
            </FarmerCards>
          </FarmerList>
        </FarmerSourcing>
        {/* <SupplierTypes>
          <SourcingFarmer>OCOP Supplier</SourcingFarmer>
          <Infocard1>
            <Infocardcontentmain1>
              <Infocardcontentfooter>
                <DailyCatches>Daily catches</DailyCatches>
                <BinhDienMarket>
                  Binh Dien Market is one of the largest agricultural food
                  markets in Ho Chi Minh City. This is also the only wholesale
                  market supplying seafood to the market here.
                </BinhDienMarket>
              </Infocardcontentfooter>
            </Infocardcontentmain1>
          </Infocard1>
        </SupplierTypes>
        <SupplierTypes1>
          <Supplierzonelistcard1>
            <Supplierzonecontent>
              <TheWestCentralPacificParent>
                <TheWestCentralContainer>
                  <TheWestCentralPacific>
                    <TheWestCentral>The West Central Pacific</TheWestCentral>
                  </TheWestCentralPacific>
                </TheWestCentralContainer>
                <TheWestCentralContainer>
                  <TheWestCentralPacific>
                    <TheWestCentral>The West Central Pacific</TheWestCentral>
                  </TheWestCentralPacific>
                </TheWestCentralContainer>
                <TheWestCentralContainer>
                  <TheWestCentralPacific>
                    <TheWestCentral>The West Central Pacific</TheWestCentral>
                  </TheWestCentralPacific>
                </TheWestCentralContainer>
                <TheWestCentralContainer>
                  <TheWestCentralPacific>
                    <TheWestCentral>The West Central Pacific</TheWestCentral>
                  </TheWestCentralPacific>
                </TheWestCentralContainer>
                <TheWestCentralContainer>
                  <TheWestCentralPacific>
                    <TheWestCentral>The West Central Pacific</TheWestCentral>
                  </TheWestCentralPacific>
                </TheWestCentralContainer>
              </TheWestCentralPacificParent>
              <Cardfooterbutton>See All</Cardfooterbutton>
            </Supplierzonecontent>
          </Supplierzonelistcard1>
        </SupplierTypes1> */}
      </SupplierCards>
      <GoMarketButton goToTheMarketPage="Go to regional farming supplier" />
    </FindsupplierlowestpricesectionRoot>
  );
};

FindSupplierLowestPriceSection.propTypes = {
  className: PropTypes.string,
};

export default FindSupplierLowestPriceSection;
