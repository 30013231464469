import styled from "styled-components";
import PropTypes from "prop-types";

const OurAuctionPlatform = styled.div`
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
`;
const Titleblock = styled.div`
  align-self: stretch;
  border-radius: var(--br-xl);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-26xl) var(--padding-23xl);
  box-sizing: border-box;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    gap: var(--gap-8xl);
    padding-left: var(--padding-2xl);
    padding-right: var(--padding-2xl);
    box-sizing: border-box;
  }
`;
const StepIcon = styled.div`
  position: absolute;
  top: 1px;
  left: 0px;
  border-radius: 50%;
  background-color: var(--light-gray);
  width: 47px;
  height: 47px;
`;
const B = styled.b`
  position: absolute;
  top: 0px;
  left: 13px;
  line-height: 150%;
  display: inline-block;
  min-width: 20px;
  z-index: 1;
  @media screen and (max-width: 800px) {
    font-size: var(--font-size-7xl);
    line-height: 38px;
  }
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-lgi);
    line-height: 29px;
  }
`;
const StepOne = styled.div`
  margin-top: -11px;
  height: 48px;
  width: 47px;
  position: relative;
`;
const RegisterYourAccount = styled.div`
  position: relative;
  font-size: var(--regular-text-20-size);
  line-height: 27px;
  font-family: var(--regular-text-20);
  color: var(--color-black);
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
`;
const First = styled.div`
  height: 47px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-3xs) 0px 0px;
  box-sizing: border-box;
  gap: var(--gap-11xl);
`;
const Process = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  font-size: var(--font-size-13xl);
  color: var(--salad);
  font-family: var(--font-ibm-plex-sans);
`;
const Rowleft = styled.div`
  flex: 1;
  border-radius: var(--br-xl);
  background-color: var(--neutral-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-xl) var(--padding-69xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  min-height: 462px;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    padding-bottom: var(--padding-38xl);
    box-sizing: border-box;
  }
`;
const Wm2Icon = styled.img`
  align-self: stretch;
  flex: 1;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
`;
const Featuredimage = styled.div`
  align-self: stretch;
  height: 247px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Description = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: 100%;
  flex-shrink: 0;
`;
const OurAuctionPlatform1 = styled.div`
  flex: 1;
  position: relative;
  line-height: 27px;
  display: inline-block;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
`;
const Info = styled.div`
  align-self: stretch;
  height: 89px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 0px;
  box-sizing: border-box;
  gap: var(--gap-3xs);
  max-width: 100%;
`;
const Rowright = styled.div`
  flex: 0.9214;
  border-radius: var(--br-xl);
  background-color: var(--neutral-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-29xl) var(--padding-23xl);
  box-sizing: border-box;
  gap: var(--gap-11xl);
  max-width: 100%;
  @media screen and (max-width: 800px) {
    gap: var(--gap-mini);
    padding-left: var(--padding-2xl);
    padding-right: var(--padding-2xl);
    box-sizing: border-box;
    flex: 1;
  }
  @media screen and (max-width: 450px) {
    padding-top: var(--padding-12xl);
    padding-bottom: var(--padding-12xl);
    box-sizing: border-box;
  }
`;
const WebsiteprocessRoot = styled.section`
  align-self: stretch;
  background-color: var(--light-gray);
  display: flex;
  flex-direction: row;
  ${"" /* align-items: flex-start; */}
  justify-content: flex-start;
  padding: var(--padding-68xl) var(--padding-91xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
  z-index: 4;
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--color-gray-200);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 1350px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 800px) {
    padding: var(--padding-38xl) var(--padding-36xl);
    box-sizing: border-box;
    flex-direction: column;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 450px) {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
    flex-wrap: wrap;
  }
`;

const WebsiteProcess = ({ className = "" }) => {
  return (
    <WebsiteprocessRoot className={className}>
      <Rowleft>
        <Titleblock>
          <OurAuctionPlatform>
            Our auction platform makes international trades that were once
            impossible, a reality
          </OurAuctionPlatform>
        </Titleblock>
        <Process>
          <First>
            <StepOne>
              <StepIcon />
              <B>1</B>
            </StepOne>
            <RegisterYourAccount>Register Your Account</RegisterYourAccount>
          </First>
          <First>
            <StepOne>
              <StepIcon />
              <B>2</B>
            </StepOne>
            <RegisterYourAccount>Bid on Commodities</RegisterYourAccount>
          </First>
          <First>
            <StepOne>
              <StepIcon />
              <B>3</B>
            </StepOne>
            <RegisterYourAccount>Secure the Product</RegisterYourAccount>
          </First>
        </Process>
      </Rowleft>
      <Rowright>
        <Featuredimage>
          <Wm2Icon loading="lazy" alt="" src="/wm-2@2x.png" />
        </Featuredimage>
        <Info>
          <Description>
            <OurAuctionPlatform>Connecting World Markets</OurAuctionPlatform>
          </Description>
          <Description>
            <OurAuctionPlatform1>
              Our auction platform makes international trades that were once
              impossible, a reality.
            </OurAuctionPlatform1>
          </Description>
        </Info>
      </Rowright>
    </WebsiteprocessRoot>
  );
};

WebsiteProcess.propTypes = {
  className: PropTypes.string,
};

export default WebsiteProcess;
