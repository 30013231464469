import styled from "styled-components";
import FrameComponent from "./FrameComponent";
import PropTypes from "prop-types";
import HomeAuctionCard from "./HomeAuctionCard";

const FeaturedAuctions = styled.div`
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
  text-align: start;
  width: 100%;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
`;
const Image33Icon = styled.img`
  height: 472px;
  position: relative;
  border-radius: var(--br-xl);
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
`;
const Featuredimage = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-xl);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url("/image-333@2x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 100%;
`;
const Rowright = styled.div`
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-xl);
  background-color: var(--neutral-white);
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-xl) var(--padding-mini);
  box-sizing: border-box;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    flex: 1;
  }
`;
const RightImageContainer = styled.div`
  align-self: stretch;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 0px var(--padding-9xl);
  box-sizing: border-box;
  max-width: 100%;
`;
const ScrollindicatorChild = styled.div`
  height: 12px;
  width: 12px;
  position: relative;
  border-radius: 50%;
  background-color: var(--neutral-white);
`;
const ScrollindicatorItem = styled.div`
  height: 12px;
  width: 12px;
  position: relative;
  border-radius: 50%;
  background-color: var(--color-gray-200);
`;
const Scrollindicator = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const ScrollContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-26xl);
  min-width: 407px;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    gap: var(--gap-3xl);
    min-width: 100%;
  }
`;
const FrameGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  max-width: calc(100% - 294px);
  overflow-x: auto;
  @media screen and (max-width: 800px) {
    max-width: 100%;
  }
`;
const FrameParent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`;
const RecentlyViewedInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  padding: 0px 0px 0px var(--padding-3xs);
  box-sizing: border-box;
  max-width: 100%;
  color: var(--color-black);
  font-family: var(--font-ibm-plex-sans);
  gap: 20px;
`;
const RecentlyViewedChild = styled.div`
  position: relative;
  background-color: var(--color-whitesmoke-200);
  display: none;
  max-width: 100%;
`;
const RecentlyViewedRoot = styled.section`
  align-self: stretch;
  background-color: var(--light-gray);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-61xl);
  box-sizing: border-box;
  gap: var(--gap-11xl);
  max-width: 100%;
  z-index: 1;
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--color-gray-400);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 800px) {
    padding: var(--padding-33xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding: 20px;
    box-sizing: border-box;
  }
`;

const RecentlyViewed = ({ className = "" }) => {
  return (
    <RecentlyViewedRoot className={className}>
      <FeaturedAuctions>Recently Viewed</FeaturedAuctions>
      <RecentlyViewedInner>
        <FrameParent>
          <FrameGroup>
            <HomeAuctionCard image33="/image-33@2x.png" />
            <HomeAuctionCard image33="/image-33@2x.png" />
          </FrameGroup>

          <Image33Icon alt="" src="/image-333@2x.png" />
        </FrameParent>

        <Scrollindicator>
          <ScrollindicatorChild />
          <ScrollindicatorItem />
        </Scrollindicator>
      </RecentlyViewedInner>
      <RecentlyViewedChild />
    </RecentlyViewedRoot>
  );
};

RecentlyViewed.propTypes = {
  className: PropTypes.string,
};

export default RecentlyViewed;
