import styled from "styled-components";
import PropTypes from "prop-types";

const BinhDien = styled.li``;
const BinhDien1 = styled.ul`
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: var(--padding-xl);
`;
const BinhDien2 = styled.div`
  position: relative;
  line-height: 24px;
  text-transform: capitalize;
  display: inline-block;
  min-width: 90px;
`;
const ThuDuc = styled.div`
  width: 90px;
  position: relative;
  line-height: 24px;
  text-transform: capitalize;
  display: inline-block;
  flex-shrink: 0;
`;
const MarketlistsRoot = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-25xl) var(--padding-6xs) 0px;
  box-sizing: border-box;
  gap: 5px 12px;
  min-width: 159px;
  min-height: 176px;
  text-align: left;
  font-size: var(--regular-text-15-size);
  color: var(--color-gray-200);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 1350px) {
    padding-right: var(--padding-3xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 800px) {
    min-height: auto;
  }
`;

const MarketLists = ({ className = "" }) => {
  return (
    <MarketlistsRoot className={className}>
      <BinhDien2>
        <BinhDien1>
          <BinhDien>Binh Dien</BinhDien>
        </BinhDien1>
      </BinhDien2>
      <BinhDien2>
        <BinhDien1>
          <BinhDien>Binh Dien</BinhDien>
        </BinhDien1>
      </BinhDien2>
      <BinhDien2>
        <BinhDien1>
          <BinhDien>Binh Dien</BinhDien>
        </BinhDien1>
      </BinhDien2>
      <ThuDuc>
        <BinhDien1>
          <BinhDien>Thu Duc</BinhDien>
        </BinhDien1>
      </ThuDuc>
      <ThuDuc>
        <BinhDien1>
          <BinhDien>Thu Duc</BinhDien>
        </BinhDien1>
      </ThuDuc>
      <ThuDuc>
        <BinhDien1>
          <BinhDien>Thu Duc</BinhDien>
        </BinhDien1>
      </ThuDuc>
      <ThuDuc>
        <BinhDien1>
          <BinhDien>Hoc Mon</BinhDien>
        </BinhDien1>
      </ThuDuc>
      <ThuDuc>
        <BinhDien1>
          <BinhDien>Hoc Mon</BinhDien>
        </BinhDien1>
      </ThuDuc>
      <ThuDuc>
        <BinhDien1>
          <BinhDien>Hoc Mon</BinhDien>
        </BinhDien1>
      </ThuDuc>
      <ThuDuc>
        <BinhDien1>
          <BinhDien>Cai Be</BinhDien>
        </BinhDien1>
      </ThuDuc>
      <ThuDuc>
        <BinhDien1>
          <BinhDien>Cai Be</BinhDien>
        </BinhDien1>
      </ThuDuc>
      <ThuDuc>
        <BinhDien1>
          <BinhDien>Cai Be</BinhDien>
        </BinhDien1>
      </ThuDuc>
    </MarketlistsRoot>
  );
};

MarketLists.propTypes = {
  className: PropTypes.string,
};

export default MarketLists;
