import styled from "styled-components";
import PropTypes from "prop-types";

const FeaturedAuctions = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 150%;
  text-transform: uppercase;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 24px;
  }
`;
const Titleblock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
`;
const AvatarIcon = styled.img`
  height: 150px;
  width: 150px;
  position: relative;
  border-radius: 50%;
  object-fit: cover;
  @media screen and (max-width: 800px) {
    height: 100px;
    width: 100px;
  }
`;
const JaneGaron = styled.div`
  position: relative;
  line-height: 22px;
  font-weight: 600;
  display: inline-block;
  min-width: 111px;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 18px;
  }
`;
const OwnerOfJane = styled.div`
  position: relative;
  font-size: var(--regular-text-15-size);
  line-height: 24px;
  text-transform: capitalize;
  white-space: nowrap;
`;
const ReviewerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl) 0px;
  gap: var(--gap-7xs);
`;
const Userinfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  min-width: 406px;
  max-width: 100%;
  @media screen and (max-width: 1125px) {
    flex: 1;
  }
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    min-width: 100%;
  }
`;
const BorongsServiceTeam = styled.blockquote`
  margin: 0;
  height: 150px;
  flex: 1;
  position: relative;
  letter-spacing: -0.03px;
  line-height: 24px;
  display: inline-block;
  max-width: 100%;
`;
const Reviewdetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 481px;
  max-width: 100%;
  font-size: var(--font-size-mid);
  @media screen and (max-width: 800px) {
    min-width: 100%;
  }
`;
const Review = styled.div`
  align-self: stretch;
  border-radius: var(--br-xl);
  background-color: var(--light-gray);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-11xl);
  box-sizing: border-box;
  gap: var(--gap-3xs);
  max-width: 100%;
  flex-shrink: 0;
  @media screen and (max-width: 1125px) {
    flex-wrap: wrap;
  }
`;
const Reviews = styled.div`
  width: 1216px;
  height: 76.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 0px 0px;
  box-sizing: border-box;
  max-width: 100%;
  color: var(--color-gray-200);
  @media screen and (max-width: 1125px) {
    height: auto;
  }
`;
const VectorIcon = styled.img`
  height: 32px;
  width: 17px;
  position: relative;
  transform: rotate(180deg);
`;
const Arrowleft = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 92.3px;
  left: 1199px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
const VectorIcon1 = styled.img`
  height: 32px;
  width: 17px;
  position: relative;
  transform: rotate(-180deg);
`;
const Arrowright = styled.div`
  margin: 0 !important;
  position: absolute;
  top: 112.3px;
  right: 1182px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  transform: rotate(180deg);
  transform-origin: 0 0;
`;
const Arrows = styled.div`
  width: 1216px;
  height: 76.5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 0px var(--padding-57xl);
  box-sizing: border-box;
  position: relative;
  transform: rotate(-180deg);
  max-width: 100%;
`;
const CustomerreviewsRoot = styled.section`
  align-self: stretch;
  background-color: var(--neutral-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-61xl) var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-38xl);
  max-width: 100%;
  z-index: 3;
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--color-gray-400);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 800px) {
    gap: var(--gap-9xl);
  }
  @media screen and (max-width: 450px) {
    padding-top: var(--padding-33xl);
    padding-bottom: var(--padding-33xl);
    box-sizing: border-box;
  }
`;

const CustomerReviews = ({ className = "" }) => {
  return (
    <CustomerreviewsRoot className={className}>
      <Titleblock>
        <FeaturedAuctions>Customer Reviews</FeaturedAuctions>
      </Titleblock>
      <Reviews>
        <Review>
          <Userinfo>
            <AvatarIcon loading="lazy" alt="" src="/ellipse-4@2x.png" />
            <ReviewerInfo>
              <JaneGaron>Jane Garon</JaneGaron>
              <OwnerOfJane>Owner of Jane Garon Kiosk</OwnerOfJane>
            </ReviewerInfo>
          </Userinfo>
          <Reviewdetails>
            <BorongsServiceTeam>
              "Borong's service team has done an exceptional job since day one.
              I'm especially impressed with their diverse marketplace, offering
              high quality products with a long shelf life."
            </BorongsServiceTeam>
          </Reviewdetails>
        </Review>
      </Reviews>
      <Arrows>
        <Arrowleft>
          <VectorIcon alt="" src="/vector-12.svg" />
        </Arrowleft>
        <Arrowright>
          <VectorIcon1 alt="" src="/vector-12.svg" />
        </Arrowright>
      </Arrows>
    </CustomerreviewsRoot>
  );
};

CustomerReviews.propTypes = {
  className: PropTypes.string,
};

export default CustomerReviews;
