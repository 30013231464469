import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  InputAdornment,
} from "@mui/material";
import styled from "styled-components";
import GoMarketButton from "./GoMarketButton";
import PropTypes from "prop-types";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import NowButton from "./NowButton";

const AiauctionA = styled.div`
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  max-width: 100%;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
`;
const Titleblock = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
`;
const ANetworkOf = styled.p`
  margin: 0;
`;
const DemandSendInvitation = styled.p`
  margin: 0;
  white-space: pre-wrap;
`;
const ANetworkOfContainer = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 24px;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
`;
const Inputlabel1 = styled(InputLabel)``;
const AuctionNow = styled(MenuItem)``;
const Select1 = styled(Select)``;
const Formhelpertext1 = styled(FormHelperText)``;
const Nowbutton1 = styled(FormControl)`
  width: 186px;
  height: 47px;
  font-family: var(--regular-text-20);
  font-weight: 600;
  font-size: var(--regular-text-15-size);
  color: var(--neutral-white);
`;
const Aiinfobottom = styled.div`
  align-self: stretch;
  height: 145.5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px var(--padding-192xl) 0px 0px;
  box-sizing: border-box;
  @media screen and (max-width: 450px) {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;
const Aiinfoleft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-7xl);
  min-width: 395px;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    min-width: 100%;
  }
`;
const Icon1 = styled.img`
  width: 400px;
  position: relative;
  border-radius: var(--br-xl);
  max-height: 100%;
  object-fit: cover;
  max-width: 100%;
`;
const MaskGroup = styled.div`
  flex: 1;
  border-radius: var(--br-mini);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  min-width: 395px;
  max-width: 100%;
  @media screen and (max-width: 800px) {
    min-width: 100%;
  }
`;
const Frame = styled.img`
  height: 360.4px;
  width: 400px;
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 180.5px);
  left: calc(50% - 200px);
  object-fit: cover;
  z-index: 1;
`;
const Aiauctioninfoblock = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px;
  box-sizing: border-box;
  position: relative;
  max-width: 100%;
  row-gap: 20px;
  font-size: var(--font-size-mid);
  @media screen and (max-width: 800px) {
    gap: 100px;
  }
`;
const AiauctionnetworkRoot = styled.section`
  align-self: stretch;
  background-color: var(--neutral-white);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-51xl) var(--padding-93xl);
  box-sizing: border-box;
  gap: var(--gap-20xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--color-gray-200);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 800px) {
    gap: var(--gap-lgi);
    padding: var(--padding-26xl) var(--padding-37xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding-left: var(--padding-xl);
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;

const AiAuctionNetwork = ({ className = "" }) => {
  return (
    <AiauctionnetworkRoot className={className}>
      <Titleblock>
        <AiauctionA>
          Ai.Auction - A network of National Whole Seller at your Auction
        </AiauctionA>
      </Titleblock>
      <Aiauctioninfoblock>
        <Aiinfoleft>
          <ANetworkOfContainer>
            <ANetworkOf>{`A network of National WholeSalers at your Auction : - Instant Connect - Map your Sourcing Supply to Wholesale `}</ANetworkOf>
            <DemandSendInvitation>
              {" "}
              Demand - Send Invitation to Thousands Wholesalers
            </DemandSendInvitation>
            <DemandSendInvitation> for Bidding</DemandSendInvitation>
          </ANetworkOfContainer>
          <Aiinfobottom>
            <NowButton
              nowButton={"AUCTION NOW"}
              propWidth="186px"
              propFlex="unset"
              propMinWidth="unset"
              background="#FB811F"
              color="#fff"
            />
          </Aiinfobottom>
        </Aiinfoleft>
        <MaskGroup>
          <Icon1 loading="lazy" alt="" src="/2149182002-1@2x.png" />
        </MaskGroup>
        <Frame loading="lazy" alt="" src="/frame-619-3@2x.png" />
      </Aiauctioninfoblock>
      <GoMarketButton goToTheMarketPage="More about auction" />
    </AiauctionnetworkRoot>
  );
};

AiAuctionNetwork.propTypes = {
  className: PropTypes.string,
};

export default AiAuctionNetwork;
