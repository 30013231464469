import { Button } from "@mui/material";
import styled from "styled-components";
import PropTypes from "prop-types";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const SearchFoodCategory = styled.div`
  position: relative;
  letter-spacing: -0.02em;
  line-height: 27px;
  text-transform: uppercase;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: var(--font-size-base);
    line-height: 22px;
  }
`;
const SearchAuctionBidding = styled.div`
  position: relative;
  line-height: 24px;
  text-transform: capitalize;
`;
const SearchInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-mini) 0px 0px;
`;
const Searchbutton = styled(Button)`
  height: 54px;
  width: 122px;
`;
const Searchbar = styled.div`
  width: 716px;
  border-radius: var(--br-21xl);
  background-color: var(--color-whitesmoke-100);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px 0px 0px var(--padding-177xl);
  box-sizing: border-box;
  gap: var(--gap-xl);
  max-width: 100%;
  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
    padding-left: var(--padding-xl);
    ${
      "" /* padding-right: var(--padding-xl);
    padding-bottom: var(--padding-xl); */
    }
    box-sizing: border-box;
  }
`;
const SearchbarWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  font-size: var(--regular-text-15-size);
  color: var(--color-gray-100);
`;
const SearchInput1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  gap: var(--gap-xl);
  width: 100%;
`;
const DailyCatch = styled.div`
  position: relative;
  line-height: 27px;
  text-transform: capitalize;
  font-weight: 600;
  display: inline-block;
  min-width: 86px;
`;
const Catbutton = styled.div`
  border-radius: var(--br-21xl);
  background-color: var(--color-olivedrab);
  border: 1px solid var(--neutral-white);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-xl) var(--padding-2xs);
  white-space: nowrap;
`;
const FreshHarvest = styled.div`
  position: relative;
  font-size: var(--regular-text-15-size);
  line-height: 27px;
  text-transform: capitalize;
  font-weight: 600;
  font-family: var(--regular-text-20);
  color: var(--neutral-white);
  text-align: left;
  display: inline-block;
  min-width: 108px;
`;
const Buttonname = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-sm) var(--padding-2xs) var(--padding-smi)
    var(--padding-xl);
`;
const Catbutton1 = styled.button`
  cursor: pointer;
  border: 1px solid var(--neutral-white);
  padding: 0;
  background-color: var(--color-olivedrab);

  border-radius: var(--br-21xl);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 90px;
`;
const Catbutton2 = styled(Button)`
  height: 54px;
  width: 126px;
`;
const Catbutton3 = styled(Button)`
  height: 54px;
  width: 100px;
`;
const Meat = styled.div`
  position: relative;
  line-height: 27px;
  text-transform: capitalize;
  font-weight: 600;
  display: inline-block;
  min-width: 38px;
`;
const Catbutton4 = styled.div`
  border-radius: var(--br-21xl);
  background-color: var(--color-olivedrab);
  border: 1px solid var(--neutral-white);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xs) var(--padding-lgi) var(--padding-2xs)
    var(--padding-xl);
`;
const CategoryButtons = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: var(--gap-sm);
  max-width: 100%;
`;
const FoodCategories = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px var(--padding-27xl);
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--regular-text-15-size);
  @media screen and (max-width: 800px) {
    padding-left: var(--padding-4xl);
    padding-right: var(--padding-4xl);
    box-sizing: border-box;
  }
`;
const SearchfoodcategorysectionRoot = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 50px;
  box-sizing: border-box;
  gap: var(--gap-29xl-5);
  background-image: url("/searchfoodcategorysection@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 100%;
  text-align: left;
  font-size: var(--regular-text-20-size);
  color: var(--neutral-white);
  font-family: var(--regular-text-20);
  @media screen and (max-width: 800px) {
    gap: var(--gap-5xl);
    padding-left: var(--padding-6xl);
    padding-right: var(--padding-6xl);
    box-sizing: border-box;
  }
  @media screen and (max-width: 450px) {
    padding-right: var(--padding-xl);
    box-sizing: border-box;
  }
`;

const Div = styled.div`
  overflow-x: scroll;
  @media (max-width: 800px) {
    width: 100%;
  }
`;
const Row = styled.div`
  display: inline-grid;
  flex-direction: row;
  justify-content: start;
  grid-template-columns: repeat(2, minmax(285px, 300px));
  gap: 20px;
  overflow-x: scroll;
`;

const SearchFoodCategorySection = ({ className = "" }) => {
  return (
    <SearchfoodcategorysectionRoot className={className}>
      <SearchInput1>
        <SearchFoodCategory>Search Food Category</SearchFoodCategory>
        <SearchbarWrapper>
          <Searchbar>
            <SearchInput>
              <SearchAuctionBidding>{`Search Auction Bidding `}</SearchAuctionBidding>
            </SearchInput>
            <Searchbutton
              endIcon={<ArrowRightAltIcon />}
              disableElevation
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "15",
                background: "#fb811f",
                borderRadius: "0px 30px 30px 0px",
                "&:hover": { background: "#fb811f" },
                width: 122,
                height: 54,
              }}
            >{`Search `}</Searchbutton>
          </Searchbar>
        </SearchbarWrapper>
        <FoodCategories>
          <CategoryButtons>
            <Catbutton>
              <DailyCatch>Daily Catch</DailyCatch>
            </Catbutton>
            <Catbutton1>
              <Buttonname>
                <FreshHarvest>Fresh Harvest</FreshHarvest>
              </Buttonname>
            </Catbutton1>
            <Catbutton2
              disableElevation
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "15",
                background: "#5e9e2f",
                border: "#fff solid 1px",
                borderRadius: "40px",
                "&:hover": { background: "#5e9e2f" },
                width: 126,
                height: 54,
              }}
            >
              Dried Foods
            </Catbutton2>
            <Catbutton3
              disableElevation
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "15",
                background: "#5e9e2f",
                border: "#fff solid 1px",
                borderRadius: "40px",
                "&:hover": { background: "#5e9e2f" },
                width: 100,
                height: 54,
              }}
            >
              SeaFood
            </Catbutton3>
            <Catbutton4>
              <Meat>Meat</Meat>
            </Catbutton4>
          </CategoryButtons>
        </FoodCategories>
      </SearchInput1>
    </SearchfoodcategorysectionRoot>
  );
};

SearchFoodCategorySection.propTypes = {
  className: PropTypes.string,
};

export default SearchFoodCategorySection;
