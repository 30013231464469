import { Button } from "@mui/material";
import styled from "styled-components";
import PropTypes from "prop-types";

const NowbuttonRoot = styled(Button)`height: 50px;
  width: 187px;
  width: ${(p) => p.propWidth}
  flex: ${(p) => p.propFlex}
  min-width: ${(p) => p.propMinWidth}
`;

const NowButton = ({
  className = "",
  nowButton,
  propWidth,
  propFlex,
  propMinWidth,
  background = "#fff",
  color = "#222",
}) => {
  return (
    <NowbuttonRoot
      disableElevation
      variant="contained"
      sx={{
        textTransform: "none",
        color: color,
        fontSize: "17",
        background: background,
        borderRadius: "40px",
        "&:hover": { background: "#fff", color: "#222" },
        width: 187,
        height: 50,
      }}
      propWidth={propWidth}
      propFlex={propFlex}
      propMinWidth={propMinWidth}
      className={className}
    >
      {nowButton}
    </NowbuttonRoot>
  );
};

NowButton.propTypes = {
  className: PropTypes.string,
  nowButton: PropTypes.string,

  /** Style props */
  propWidth: PropTypes.any,
  propFlex: PropTypes.any,
  propMinWidth: PropTypes.any,
};

export default NowButton;
