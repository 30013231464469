import { Button } from "@mui/material";
import styled from "styled-components";
import PropTypes from "prop-types";

const VisitbuttonRoot = styled(Button)`
  align-self: stretch;
  flex: 1;
`;

const VisitButton = ({ className = "" }) => {
  return (
    <VisitbuttonRoot
      endIcon={
        <img
          width="24px"
          height="24px"
          src="/Arrow/Arrow_Left_LG2.svg"
          className={className}
        />
      }
      disableElevation
      variant="contained"
      sx={{
        textTransform: "none",
        color: "#7ac143",
        fontSize: "12px",
        background: "#fff",
        border: "#7ac143 solid 1px",
        borderRadius: "33px",
        "&:hover": { background: "#fff" },
      }}
    >
      Visit store
    </VisitbuttonRoot>
  );
};

VisitButton.propTypes = {
  className: PropTypes.string,
};

export default VisitButton;
