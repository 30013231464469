import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    body {
      margin: 0; line-height: normal;
    }
:root {

/* fonts */
--font-ibm-plex-sans: 'IBM Plex Sans';
--regular-text-20: Inter;
--font-poppins: Poppins;
--font-helvetica: Helvetica;

/* font sizes */
--regular-14-size: 14px;
--regular-text-20-size: 20px;
--font-size-base: 16px;
--font-size-mid: 17px;
--font-size-13xl: 32px;
--font-size-lgi: 19px;
--font-size-7xl: 26px;
--regular-text-15-size: 15px;
--font-size-5xl: 24px;
--semibold-12-size: 12px;
--font-size-16xl: 35px;
--font-size-2xl: 21px;
--font-size-9xl: 28px;
--font-size-3xl: 22px;
--font-size-33xl: 52px;
--font-size-12xl: 31px;
--font-size-23xl: 42px;
--font-size-lg: 18px;

/* Colors */
--medium-gray: #646565;
--color-dimgray-100: #4d4d4d;
--light-gray: #f6f6f6;
--color-whitesmoke-100: #f3f3f3;
--color-whitesmoke-200: #efefef;
--neutral-white: #fff;
--color-gray-100: #797979;
--color-gray-200: #222;
--color-gray-300: #101828;
--color-gray-400: #070707;
--color-gray-500: rgba(0, 0, 0, 0.44);
--color-gray-600: rgba(255, 255, 255, 0.2);
--color-gray-700: rgba(255, 255, 255, 0.9);
--color-gray-800: rgba(0, 0, 0, 0.48);
--color-slategray: #667085;
--color-black: #000;
--salad: #7ac143;
--color-darkorange: #fb811f;
--blue: #4c8cd1;
--gray-light2: #e4e5e3;
--color-gainsboro-100: #d9d9d9;
--color-olivedrab: #5e9e2f;
--color-chocolate: #c74f00;

/* Gaps */
--gap-5xs: 8px;
--gap-28xl: 47px;
--gap-4xl: 23px;
--gap-5xl: 24px;
--gap-xs: 12px;
--gap-21xl: 40px;
--gap-xl: 20px;
--gap-sm: 14px;
--gap-lg: 18px;
--gap-base: 16px;
--gap-3xs: 10px;
--gap-11xl: 30px;
--gap-13xl: 32px;
--gap-7xs: 6px;
--gap-mini: 15px;
--gap-8xl: 27px;
--gap-38xl: 57px;
--gap-9xl: 28px;
--gap-sm-5: 13.5px;
--gap-9xs: 4px;
--gap-11xs: 2px;
--gap-26xl: 45px;
--gap-3xl: 22px;
--gap-20xl: 39px;
--gap-lgi: 19px;
--gap-7xl: 26px;
--gap-192xl: 211px;
--gap-86xl: 105px;
--gap-8xs: 5px;
--gap-31xl: 50px;
--gap-6xl: 25px;
--gap-mid: 17px;
--gap-lgi-7: 19.7px;
--gap-12xs: 1px;
--gap-4xl-3: 23.3px;
--gap-10xs: 3px;
--gap-29xl-5: 48.5px;
--gap-17xl: 36px;
--gap-0: 0px;
--gap-27xl: 46px;
--gap-22xl: 41px;
--gap-smi: 13px;
--gap-6xs: 7px;
--gap-4xs: 9px;
--gap-11xs-1: 1.1px;
--gap-12xs-7: 0.7px;

/* Paddings */
--padding-45xl: 64px;
--padding-146xl: 165px;
--padding-23xl: 42px;
--padding-xl: 20px;
--padding-63xl: 82px;
--padding-80xl: 99px;
--padding-9xs: 4px;
--padding-2xs: 11px;
--padding-10xs: 3px;
--padding-xs: 12px;
--padding-9xs-5: 3.5px;
--padding-12xs: 1px;
--padding-192xl: 211px;
--padding-61xl: 80px;
--padding-93xl: 112px;
--padding-33xl: 52px;
--padding-37xl: 56px;
--padding-base: 16px;
--padding-230xl: 249px;
--padding-143xl: 162px;
--padding-68xl: 87px;
--padding-91xl: 110px;
--padding-38xl: 57px;
--padding-36xl: 55px;
--padding-29xl: 48px;
--padding-12xl: 31px;
--padding-2xl: 21px;
--padding-69xl: 88px;
--padding-3xs: 10px;
--padding-26xl: 45px;
--padding-57xl: 76px;
--padding-11xl: 30px;
--padding-28xl: 47px;
--padding-smi: 13px;
--padding-mini: 15px;
--padding-157xl: 176px;
--padding-4xl: 23px;
--padding-3xl: 22px;
--padding-9xl: 28px;
--padding-144xl: 163px;
--padding-lgi: 19px;
--padding-50xl: 69px;
--padding-31xl: 50px;
--padding-13xl: 32px;
--padding-6xl: 25px;
--padding-44xl: 63px;
--padding-48xl: 67px;
--padding-14xl: 33px;
--padding-51xl: 70px;
--padding-41xl: 60px;
--padding-20xl: 39px;
--padding-4xs: 9px;
--padding-lg: 18px;
--padding-5xs: 8px;
--padding-343xl: 362px;
--padding-52xl-5: 71.5px;
--padding-162xl: 181px;
--padding-27xl: 46px;
--padding-sm: 14px;
--padding-177xl: 196px;
--padding-16xl: 35px;
--padding-241xl: 260px;
--padding-111xl: 130px;
--padding-3xs-5: 9.5px;
--padding-30xl: 49px;
--padding-6xs: 7px;
--padding-5xl: 24px;
--padding-25xl: 44px;
--padding-56xl: 75px;
--padding-603xl: 622px;
--padding-136xl: 155px;
--padding-292xl: 311px;
--padding-10xl: 29px;
--padding-19xl: 38px;
--padding-42xl: 61px;
--padding-49xl: 68px;
--padding-183xl: 202px;
--padding-82xl: 101px;
--padding-51xl-5: 70.5px;
--padding-mini-8: 14.8px;
--padding-8xs: 5px;
--padding-26xl-6: 45.6px;
--padding-base-4: 15.4px;
--padding-43xl: 62px;
--padding-11xs: 2px;

/* Border radiuses */
--br-5xs: 8px;
--br-xl: 20px;
--br-mini: 15px;
--br-4xl: 23px;
--br-3xs: 10px;
--br-21xl: 40px;
--br-61xl: 80px;
--br-81xl: 100px;
--br-10xs: 3px;
--br-12xs: 1px;

}
`;
